import React, { Fragment, useEffect } from 'react'
import Content from '../../../types/content'
import './Content.scss'
import { UUID } from '../../../types/common'
import SortableList from '../../../components/SortableList'
import { Answer } from '../../../types/answer'
import XAnswer from '../Answer'

interface Props {
  content: Content
  selectedAnswers: UUID[]
  orderedAnswers: Answer[]
  onAnswersReorder: (from: number, to: number) => void
  onChange: (ids: UUID[]) => void
  disabled?: boolean
  onVideoEnded?: () => void
}

export default (props: Props) => {
  const onChange = (id: UUID, value: boolean) => {
    if (value) {
      if (props.content.contentType == 'one_answer_question') {
        props.onChange([id])
      } else {
        props.onChange([...props.selectedAnswers, id])
      }
    } else {
      props.onChange(props.selectedAnswers.filter((a) => a != id))
    }
  }

  const getExtension = (filename: string) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  const isVideo = (filename: string) => {
    const ext = getExtension(filename)

    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
        return true
    }
    return false
  }

  return (
    <div className="Content">
      {props.content && (
        <Fragment>
          {props.content.contentType != 'video' && props.content.imageUrl && (
            <div className="Content__image">
              {isVideo(props.content.imageUrl) ? (
                <video src={props.content.imageUrl} controls />
              ) : (
                <img src={props.content.imageUrl} />
              )}
            </div>
          )}
          <div className="Content__title">
            <div className="Content__titlePoints">
              {props.content.points} points
            </div>
            <div className="Content__titleText">{props.content.title}</div>
          </div>
          {props.content.contentType == 'video' && (
            <video
              src={props.content.imageUrl}
              autoPlay
              onEnded={props.onVideoEnded}
            />
          )}
          {['one_answer_question', 'multiple_answer_question'].includes(
            props.content.contentType
          ) && (
            <div className="Content__answers">
              {props.content.answers.map((a) => {
                return (
                  <XAnswer
                    title={a.value}
                    singleSelection={
                      props.content.contentType == 'one_answer_question'
                    }
                    value={a.id}
                    onChange={(v) => onChange(a.id, v)}
                    selected={props.selectedAnswers.includes(a.id)}
                    key={a.id}
                    disabled={props.disabled}
                  />
                )
              })}
            </div>
          )}
          {props.content.contentType == 'sortable_question' && (
            <div className="Content__answers">
              <SortableList
                onReorder={props.onAnswersReorder}
                items={props.orderedAnswers.map((a) => ({
                  id: a.id,
                  content: (
                    <XAnswer
                      title={a.value}
                      singleSelection={
                        props.content.contentType == 'one_answer_question'
                      }
                      reorderable
                      value={a.id}
                      onChange={(v) => onChange(a.id, v)}
                      selected={props.selectedAnswers.includes(a.id)}
                      key={a.id}
                      disabled={props.disabled}
                    />
                  ),
                }))}
              ></SortableList>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}
