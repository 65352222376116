import React, { useEffect, useState } from 'react'
import './Domains.scss'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography, Button, makeStyles, TextField } from '@material-ui/core'
import clsx from 'clsx'
import Theme from '../../types/theme'
import GoodPractice from '../../types/good_practice'
import Domain from '../../types/domain'

const useStyles = makeStyles({
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
  sortable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center;',
    '& i': {
      marginLeft: '0.75em',
    },
  },
  filters: {
    display: 'flex',
    marginBottom: '2em',
  },
  filter: {
    minWidth: '15em',
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

export default () => {
  const [domains, setDomains] = useState<Domain[]>([])
  const [themes, setThemes] = useState<Theme[]>([])
  const classes = useStyles()
  const [filters, setFilters] = useState({
    name: '',
  })
  const [orderBy, setOrderBy] = useState<{ field: string; type: string }>({
    field: undefined,
    type: undefined,
  })

  useEffect(() => {
    fetchDomains()
    fetchThemes()
  }, [])

  const fetchDomains = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_DOMAINS({}))

      setDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES({}))

      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve topics'))
      console.warn(e)
    }
  }

  const deleteDomain = async (domain: Domain) => {
    if (
      window.confirm(t('Êtes-vous certain de vouloir supprimer ce domaine ?'))
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_DOMAIN({ id: domain.id }))

        setDomains(domains.filter((d) => d.id != domain.id))
      } catch (e) {
        store.notify(Message.Error, t('Unable to delete the domain'))
        console.warn(e)
      }
    }
  }

  const filteredDomains = () => {
    let res = [...domains]

    if (filters.name && filters.name != '') {
      res = res.filter((d) =>
        d.name.toLowerCase().includes(filters.name.toLowerCase())
      )
    }
    return res
  }

  return (
    <div className="Themes">
      <div className={classes.titleWrapper}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Domains')}
        </Typography>
        <a className={classes.linkButton} href="/domains/new">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('+ Create')}
          </Button>
        </a>
      </div>
      <div className={classes.filters}>
        <TextField
          className={classes.filter}
          label={t('Filter by name')}
          value={filters.name}
          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'name',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Domain')}
                  {orderBy.field == 'name' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t('Topics')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orderBy.field
              ? filteredDomains().sort((t1: any, t2: any) => {
                  let a = t1[orderBy.field]
                  let b = t2[orderBy.field]

                  try {
                    a = a.toLowerCase()
                    b = b.toLowerCase()
                  } catch {}

                  let res = a >= b ? 1 : -1

                  if (orderBy.type == 'desc') res *= -1
                  return res
                })
              : filteredDomains()
            ).map((domain) => (
              <TableRow key={domain.id}>
                <TableCell scope="row">{domain.name}</TableCell>
                <TableCell scope="row">
                  {domain.themes.map((theme) => theme.name).join(', ')}
                </TableCell>
                <TableCell align="right" scope="row">
                  {store.store.JWT.role == 'superadmin' && (
                    <div className={classes.centered}>
                      <a
                        className={classes.linkButton}
                        href={`/domains/${domain.id}`}
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                        >
                          {t('Edit')}
                        </Button>
                      </a>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteDomain(domain)}
                      >
                        {t('Delete')}
                      </Button>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
