import React from 'react'
import { useState } from 'react';
import './PlusToMinus.scss'


interface Props {
  name: string
}

const PlusToMinus = ({ name }: Props) => {
  return (
    <div>
      <div className="container" >
        <input type="checkbox" id={name} style={{ display: "none" }} />
        <label htmlFor={name} id='container__button' className='to-be-changed'></label>
      </div>
    </div >
  )
}

export default PlusToMinus