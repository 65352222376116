import React, { useState, useEffect, Fragment } from 'react'
import Content, { ContentType } from '../../../types/content'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  Chip,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import './ContentForm.scss'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'
import GoodPractice from '../../../types/good_practice'
import Paper from '@material-ui/core/Paper'
import TranslatableTextField from '../../../components/TranslatableTextField'
import Language from '../../../types/language'
import clsx from 'clsx'
import SortableList from '../../../components/SortableList'
import { Answer } from '../../../types/answer'

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginRight: '0.5em',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
    },
    addButton: {
      cursor: 'pointer',
      fontSize: '0.75em',
    },
    inputField: {
      marginBottom: '1em',
    },
    tableHeadCell: {
      fontWeight: 'bold',
    },
    formControl: {
      margin: theme.spacing(1),
      width: '50em',
      display: 'flex',
      flexDirection: 'column',
    },
    contentImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    contentImageTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    contentImageFile: {
      marginBottom: '1em',
    },
    sortableAnswer: {
      display: 'flex',
      alignItems: 'center',
    },
    sortableAnswerInput: {
      flexGrow: 1,
      marginRight: '1em',
    },
    sortableAnswerIcon: {
      fontSize: '1.5em',
      marginRight: '1em',
    },
    deleteImage: {
      display: 'flex',
      alignItems: 'center',
      height: '2em',
      width: '2em',
      borderRadius: '50%',
      backgroundColor: '#f50057',
      color: 'white',
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '0.5em',
      cursor: 'pointer',
      transform: 'translateX(50%) translateY(-50%)',
    },
  })
)

const contentTypes = [
  {
    label: t('Question with one valid answer'),
    value: ContentType.OneAnswerQuestion,
  },
  {
    label: t('Question with several valid answers'),
    value: ContentType.MultipleAnswerQuestion,
  },
  {
    label: t('List to be ordered'),
    value: ContentType.SortableQuestion,
  },
  {
    label: t('Video'),
    value: ContentType.Video,
  },
]

interface Props {
  duplicateMod: boolean
}

export default (duplicateMod: Props) => {
  const [content, setContent] = useState<Content>(undefined)
  const [orderedAnswers, setOrderedAnswers] = useState<Answer[]>(undefined)
  const [languages, setLanguages] = useState<Language[]>([])
  const [goodPractices, setGoodPractices] = useState<GoodPractice[]>([])
  const [licenses, setLicenses] = useState<License[]>([])
  const [selectedGoodPractices, setSelectedGoodPractices] = useState<UUID[]>([])
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()
  const [actualLanguage, setActualLanguage] = useState<Language>(undefined)

  useEffect(() => {
    fetchContent()
    fetchGoodPractices()
    fetchLicenses()
    fetchLanguages()
  }, [])

  useEffect(() => {
    if (!actualLanguage && languages.length > 0) {
      setActualLanguage(languages.find((l) => l.code == 'fr') || languages[0])
    }
  }, [languages])

  useEffect(() => {
    if (content) {
      if (content.contentType == 'sortable_question') {
        let result = [...content.answers]

        result.sort((a, b) => (a.index > b.index ? 1 : -1))
        setOrderedAnswers(result)
      }
    }
  }, [content])

  useEffect(() => {
    if (content) {
      setSelectedGoodPractices(
        selectedGoodPractices.filter((gp) => {
          const goodPractice = goodPractices.find((gp2) => gp2.id == gp)

          return goodPractice
            ? !goodPractice.licenseId ||
            goodPractice.licenseId == content.licenseId
            : false
        })
      )
    }
  }, [content?.licenseId])

  const fetchContent = async () => {
    if (!id) {
      setContent({
        answers: [],
        contentTranslations: [],
        duration: 20,
        points: 1,
      } as Content)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_CONTENT({ id }))
        setContent(res)
        if (duplicateMod.duplicateMod) {
          setContent((prev) => ({
            ...prev,
            isDuplicated: true,
            idSourceDuplicate: id,
            id: null,
            contentTranslations: res.contentTranslations.map((cT: any, index: number) => {
              cT.id = undefined
              return cT
            }),
            answers: res.answers.map((answer: any, index: number) => {
              answer.new = true
              answer.answerTranslations.map((aT: any, index: number) => {
                aT.id = undefined
              })
              return answer
            })
          })
          )
        }
        setSelectedGoodPractices(
          res.goodPractices?.map((gp: any) => gp.id) || []
        )
      } catch (e) {
        store.notify(Message.Error, t('Unable to retrieve content'))
        console.warn(e)
      }
    }
  }

  const fetchGoodPractices = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICES({}))

      setGoodPractices(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve best practice')
      )
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES())

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const submitContent = async () => {
    let route = null
    if (duplicateMod.duplicateMod === false) {
      route = id ? ROUTES.UPDATE_CONTENT : ROUTES.CREATE_CONTENT
    } else {
      route = ROUTES.CREATE_CONTENT
    }
    try {
      const res = await httpClient.req(
        route({
          content: { ...content, selectedGoodPractices, orderedAnswers },
        })
      )

      store.notify(Message.Notification, t('Content successfully saved'))
      setContent(res)
      history.push(`/contents`)
    } catch (e) {
      store.notify(Message.Error, t('Unable to save content'))
      console.warn(e)
    }
  }

  const getExtension = (filename: string) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  const isVideo = (filename: string) => {
    const ext = getExtension(filename)

    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
        return true
    }
    return false
  }

  const updateImage = async (file: File, correction?: boolean) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      if (correction) {
        content.correctionImageUrl = res.imageUrl
      } else {
        content.imageUrl = res.imageUrl
      }

      setContent({ ...content })
    } catch (e) {
      store.notify(Message.Error, t("Unable to update the image"))
      console.warn(e)
    }
  }

  const reorderAnswers = (from: number, to: number) => {
    const result = [...orderedAnswers]
    const [removed] = result.splice(from, 1)

    result.splice(to, 0, removed)
    setOrderedAnswers(result)
  }

  const displayAnswers = () => {
    return (
      <Fragment>
        <div className={classes.titleWrapper}>
          <Typography component="h2" variant="h6" className={classes.title}>
            {t('Answers')}
          </Typography>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={() => {
              content.answers.push({
                new: true,
                value: '',
                rightAnswer: false,
                contentId: content.id,
                index: content.answers.length,
              })
              setContent({ ...content })
            }}
          >
            {t('+ Add an answer')}
          </Button>
        </div>
        {content.contentType == 'sortable_question' && orderedAnswers ? (
          <Paper>
            <SortableList
              onReorder={reorderAnswers}
              items={orderedAnswers.map((a, i) => ({
                id: a.id,
                content: (
                  <div className={classes.sortableAnswer}>
                    <i className={classes.sortableAnswerIcon}>reorder</i>
                    <div className={classes.sortableAnswerInput}>
                      <TranslatableTextField
                        value={a.answerTranslations || []}
                        translationKey="value"
                        label={t('Answer')}
                        onChange={(v) => {
                          let idx = a.id
                            ? content.answers.findIndex((a2) => a2.id == a.id)
                            : i

                          content.answers[idx].answerTranslations = v
                          setContent({ ...content })
                        }}
                        languages={languages}
                        language={actualLanguage}
                        onLanguageChange={(id) =>
                          setActualLanguage(languages.find((l) => l.id == id))
                        }
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        content.answers.splice(i, 1)
                        setContent({ ...content })
                      }}
                    >
                      {t('Delete')}
                    </Button>
                  </div>
                ),
              }))}
            />
          </Paper>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    {t('Answer')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    {t('Good answer')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {content.answers.map((a, i) => (
                  <TableRow key={a.id || i}>
                    <TableCell scope="row">
                      <TranslatableTextField
                        value={a.answerTranslations || []}
                        translationKey="value"
                        label={t('Answer')}
                        onChange={(v) => {
                          let idx = a.id
                            ? content.answers.findIndex((a2) => a2.id == a.id)
                            : i

                          content.answers[idx].answerTranslations = v
                          setContent({ ...content })
                        }}
                        languages={languages}
                        language={actualLanguage}
                        onLanguageChange={(id) =>
                          setActualLanguage(languages.find((l) => l.id == id))
                        }
                      />
                    </TableCell>
                    <TableCell scope="row">
                      {content.contentType ==
                        ContentType.MultipleAnswerQuestion ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={a.rightAnswer}
                              onChange={(e) => {
                                content.answers[i].rightAnswer =
                                  e.target.checked
                                setContent({ ...content })
                              }}
                            />
                          }
                          label=""
                        />
                      ) : (
                        <Radio
                          checked={a.rightAnswer}
                          onChange={() => {
                            content.answers.forEach((element, i2) => {
                              element.rightAnswer = i2 == i
                            })
                            setContent({ ...content })
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          content.answers.splice(i, 1)
                          setContent({ ...content })
                        }}
                      >
                        {t('Delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Fragment>
    )
  }

  const canBeSubmitted = () => {
    const hasNotEmptyName = !!content.contentTranslations.find(
      (ct) => ct.title && ct.title.length > 0
    )
    const hasDuration = !!content.duration
    const hasContentType = !!content.contentType

    return hasNotEmptyName && hasDuration && hasContentType
  }

  return (
    <div className={classes.root}>
      {duplicateMod.duplicateMod ? (
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Duplicate')} {t('content')}
        </Typography>
      ) : (
        <Typography component="h1" variant="h3" className={classes.title}>
          {id ? t('Edit') : t('Create')} {t('content')}
        </Typography>

      )}
      {content && goodPractices && languages.length > 0 ? (
        <form onSubmit={submitContent}>
          <div className={classes.formControl}>
            <FormControl className={classes.formControl}>
              <TranslatableTextField
                className={classes.inputField}
                value={content.contentTranslations || []}
                translationKey="title"
                label={t('Title')}
                onChange={(v) =>
                  setContent({ ...content, contentTranslations: v })
                }
                languages={languages}
                language={actualLanguage}
                onLanguageChange={(id) => {
                  setActualLanguage(languages.find((l) => l.id == id))
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Content type')}</InputLabel>
              <Select
                value={content.contentType || ''}
                onChange={(v) => {
                  setContent({
                    ...content,
                    contentType: v.target.value as ContentType,
                  })
                }}
                className={classes.inputField}
              >
                {contentTypes.map((ct) => (
                  <MenuItem key={ct.value} value={ct.value}>
                    {ct.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography component="h2" variant="h6" className={classes.title}>
                {content.contentType == 'video' ? t('Video') : t('Image')}
              </Typography>
              <div className={clsx(classes.contentImage, classes.inputField)}>
                {content.imageUrl &&
                  (content.contentType == 'video' ? (
                    <div style={{ position: 'relative' }}>
                      <video
                        src={content.imageUrl}
                        className={classes.contentImageTag}
                        controls
                      />
                      <div
                        className={classes.deleteImage}
                        onClick={() =>
                          setContent({ ...content, imageUrl: null })
                        }
                      >
                        <i>close</i>
                      </div>
                    </div>
                  ) : (
                    <div style={{ position: 'relative' }}>
                      {isVideo(content.imageUrl) ? (
                        <video
                          src={content.imageUrl}
                          className={classes.contentImageTag}
                          controls
                        />
                      ) : (
                        <img
                          src={content.imageUrl}
                          className={classes.contentImageTag}
                        />
                      )}
                      <div
                        className={classes.deleteImage}
                        onClick={() =>
                          setContent({ ...content, imageUrl: null })
                        }
                      >
                        <i>close</i>
                      </div>
                    </div>
                  ))}
                <input
                  type="file"
                  className={classes.contentImageFile}
                  onChange={(e) => updateImage(e.target.files[0])}
                  accept={
                    content.contentType == 'video'
                      ? 'video/*'
                      : 'video/*,image/jpeg,image/png,image/jpg'
                  }
                />
              </div>
              <label>
                Media XL
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setContent({ ...content, mediaXl: e.target.checked })
                  }
                />
              </label>
            </FormControl>
            {[
              'one_answer_question',
              'multiple_answer_question',
              'sortable_question',
            ].includes(content.contentType) && displayAnswers()}
            <FormControl className={classes.formControl}>
              <Typography component="h2" variant="h6" className={classes.title}>
                {t('Image for the answer')}
              </Typography>
              <div className={clsx(classes.contentImage, classes.inputField)}>
                {content.correctionImageUrl && (
                  <div style={{ position: 'relative' }}>
                    {isVideo(content.correctionImageUrl) ? (
                      <video
                        src={content.correctionImageUrl}
                        className={classes.contentImageTag}
                        controls
                      />
                    ) : (
                      <img
                        src={content.correctionImageUrl}
                        className={classes.contentImageTag}
                      />
                    )}
                    <div
                      className={classes.deleteImage}
                      onClick={() =>
                        setContent({ ...content, correctionImageUrl: null })
                      }
                    >
                      <i>close</i>
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  className={classes.contentImageFile}
                  onChange={(e) => updateImage(e.target.files[0], true)}
                  accept="video/*, image/jpeg, image/png, image/jpg"
                />
              </div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TranslatableTextField
                value={content.contentTranslations || []}
                translationKey="correction"
                label={t('Correction')}
                onChange={(v) =>
                  setContent({ ...content, contentTranslations: v })
                }
                wysiwyg
                languages={languages}
                language={actualLanguage}
                onLanguageChange={(id) =>
                  setActualLanguage(languages.find((l) => l.id == id))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Duration (in seconds)')}
                className={classes.inputField}
                type="number"
                value={content.duration}
                onChange={(e) =>
                  setContent({ ...content, duration: +e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Points')}
                className={classes.inputField}
                type="number"
                value={content.points}
                onChange={(e) =>
                  setContent({ ...content, points: +e.target.value })
                }
              />
            </FormControl>
            {store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('License')}</InputLabel>
                <Select
                  value={content?.licenseId || ''}
                  onChange={(v) => {
                    setContent({
                      ...content,
                      licenseId: v.target.value as UUID,
                    })
                  }}
                  className={classes.inputField}
                >
                  <MenuItem key="all" value={''}>
                    {t('No license attached')}
                  </MenuItem>
                  {licenses
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        {l.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Best practice')}</InputLabel>
              <Select
                value={selectedGoodPractices || []}
                multiple
                onChange={(v) => {
                  setSelectedGoodPractices(v.target.value as UUID[])
                }}
                className={classes.inputField}
                renderValue={(v: string[]) => (
                  <div>
                    {v.map((value) => (
                      <Chip
                        key={value}
                        label={goodPractices.find((t) => t.id == value)?.name}
                      />
                    ))}
                  </div>
                )}
              >
                {goodPractices
                  .filter(
                    (gp) =>
                      !gp.licenseId ||
                      gp.licenseId ==
                      (store.store.JWT.role == 'superadmin'
                        ? content.licenseId
                        : store.store.JWT.license_id)
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((gp) => (
                    <MenuItem key={gp.id} value={gp.id}>
                      {gp.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={submitContent}
              disabled={!canBeSubmitted()}
            >
              {t('Save')}
            </Button>
          </div>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
