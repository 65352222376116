import login from './login'
import common from './common'

interface Translations {
  [key: string]: {
    fr: string
    en: string
  }
}

const tr: Translations = {
  ...login,
  ...common,
}

export default tr
