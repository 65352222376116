import React from 'react'
import './ProgressBar.scss'

interface Props {
  progress: number
  colorStart: string
  colorEnd: string
}

export default (props: Props) => {
  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar__fill"
        style={{
          width: `${props.progress}%`,
          background: `linear-gradient(90deg,${props.colorStart} 0%,${props.colorEnd} 100%)`,
        }}
      />
    </div>
  )
}
