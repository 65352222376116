import React, { useState, useEffect } from 'react'
import GoodPractice from '../../../types/good_practice'
import { RouteComponentProps, useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  Chip,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
  ThemeProvider,
} from '@material-ui/core'
import Theme from '../../../types/theme'
import './GoodPracticeForm.scss'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'
import TranslatableTextField from '../../../components/TranslatableTextField'
import Language from '../../../types/language'

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
  })
)

export default () => {
  const [goodPractice, setGoodPractice] = useState<GoodPractice>(undefined)
  const [themes, setThemes] = useState<Theme[]>([])
  const [licenses, setLicenses] = useState<License[]>([])
  const [selectedThemes, setSelectedThemes] = useState<UUID[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [actualLanguage, setActualLanguage] = useState<Language>(undefined)
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchGoodPractice()
    fetchThemes()
    fetchLicenses()
    fetchLanguages()
  }, [])

  useEffect(() => {
    if (!actualLanguage && languages.length > 0) {
      setActualLanguage(languages.find((l) => l.code == 'fr') || languages[0])
    }
  }, [languages])

  useEffect(() => {
    if (goodPractice) {
      setSelectedThemes(
        selectedThemes.filter((t) => {
          const theme = themes.find((t2) => t2.id == t)
          return theme
            ? !theme.licenseId || theme.licenseId == goodPractice.licenseId
            : false
        })
      )
    }
  }, [goodPractice?.licenseId])

  const fetchGoodPractice = async () => {
    if (!id) {
      setGoodPractice({ keywords: [], criticity: 1 } as GoodPractice)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICE({ id }))

        setGoodPractice(res)
        setSelectedThemes(res.themes?.map((t: any) => t.id) || [])
      } catch (e) {
        store.notify(
          Message.Error,
          t('Unable to retrieve the best practice')
        )
        console.warn(e)
      }
    }
  }

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES())

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES())

      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve themes'))
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const submitGoodPractice = async () => {
    let route = id ? ROUTES.UPDATE_GOOD_PRACTICE : ROUTES.CREATE_GOOD_PRACTICE

    try {
      const res = await httpClient.req(
        route({
          goodPractice: {
            ...goodPractice,
            keywords: goodPractice.keywords.map((k) => k.trim()),
            selectedThemes,
          },
        })
      )

      store.notify(
        Message.Notification,
        t('Best practice successfully saved')
      )
      setGoodPractice(res)
      history.push(`/good_practices/`)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to save the best practice')
      )
      console.warn(e)
    }
  }

  const canBeSubmitted = () => {
    const hasNotEmptyName = !!goodPractice.goodPracticeTranslations?.find(
      (gpt) => gpt.name && gpt.name.length > 0
    )
    const hasCodification = !!goodPractice.codification

    return hasNotEmptyName && hasCodification
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {id ? t('Edit') : t('Create')} {t('a best practice')}
      </Typography>
      {goodPractice && languages.length > 0 ? (
        <form onSubmit={submitGoodPractice}>
          <FormControl className={classes.formControl}>
            <TranslatableTextField
              className={classes.inputField}
              value={goodPractice.goodPracticeTranslations || []}
              translationKey="name"
              label={t('Name')}
              onChange={(v) =>
                setGoodPractice({
                  ...goodPractice,
                  goodPracticeTranslations: v,
                })
              }
              languages={languages}
              language={actualLanguage}
              onLanguageChange={(id) =>
                setActualLanguage(languages.find((l) => l.id == id))
              }
            />
            <TextField
              required
              fullWidth
              label={t('Codification')}
              className={classes.inputField}
              value={goodPractice.codification || ''}
              onChange={(e) =>
                setGoodPractice({
                  ...goodPractice,
                  codification: e.target.value,
                })
              }
            />
            <TextField
              required
              fullWidth
              label={t('Criticality')}
              className={classes.inputField}
              type="number"
              value={goodPractice.criticity}
              onChange={(e) =>
                setGoodPractice({ ...goodPractice, criticity: +e.target.value })
              }
            />
            <TextField
              required
              fullWidth
              label={t('Keywords (separated by a comma)')}
              className={classes.inputField}
              value={goodPractice.keywords}
              onChange={(e) =>
                setGoodPractice({
                  ...goodPractice,
                  keywords: e.target.value.split(','),
                })
              }
            />
            {store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('License')}</InputLabel>
                <Select
                  placeholder={t('No license attached')}
                  value={goodPractice?.licenseId || ''}
                  onChange={(v) => {
                    setGoodPractice({
                      ...goodPractice,
                      licenseId: v.target.value as UUID,
                    })
                  }}
                  className={classes.inputField}
                >
                  <MenuItem key="all" value={''}>
                    {t('No license attached')}
                  </MenuItem>
                  {licenses
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        {l.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Topics')}</InputLabel>
              <Select
                value={selectedThemes}
                multiple
                onChange={(v) => {
                  setSelectedThemes(v.target.value as UUID[])
                }}
                className={classes.inputField}
                renderValue={(v: string[]) => (
                  <div>
                    {v.map((value) => (
                      <Chip
                        key={value}
                        label={themes.find((t) => t.id == value)?.name}
                      />
                    ))}
                  </div>
                )}
              >
                {themes
                  .filter(
                    (t) =>
                      !t.licenseId ||
                      t.licenseId ==
                      (store.store.JWT.role == 'superadmin'
                        ? goodPractice.licenseId
                        : store.store.JWT.license_id)
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((t) => (
                    <MenuItem key={t.id} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={submitGoodPractice}
              disabled={!canBeSubmitted()}
            >
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
