import React, { useEffect, useState } from 'react'
import './Licenses.scss'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography, Button, makeStyles } from '@material-ui/core'
import License from '../../types/license'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

const useStyles = makeStyles({
  root: {
    padding: '3em 5em',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
})

export default () => {
  const [licenses, setLicenses] = useState<License[]>([])
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchLicenses()
  }, [])

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES({}))

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const deleteLicense = async (license: License) => {
    if (
      window.confirm(
        t(
          'Are you sure you want to delete this license? Any dependent content will also be removed.'
        )
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_LICENSE({ id: license.id }))

        setLicenses(licenses.filter((c) => c.id != license.id))
      } catch (e) {
        store.notify(Message.Error, t('Unable to remove the license'))
        console.warn(e)
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Licenses')}
        </Typography>
        <a className={classes.linkButton} href="/licenses/new">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('+ Create')}
          </Button>
        </a>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                {t('License')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t('Address')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t('End of subscription')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t("Number of users")}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t('Domains')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenses.map((l) => (
              <TableRow key={l.id}>
                <TableCell component="th" scope="row">
                  {l.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {l.address}, {l.zipcode} {l.city}
                </TableCell>
                <TableCell component="th" scope="row">
                  {l.subscriptionEndDate
                    ? dayjs(l.subscriptionEndDate).isAfter(dayjs())
                      ? `${dayjs(l.subscriptionEndDate).format('LL')} (${dayjs(
                          l.subscriptionEndDate
                        ).diff(dayjs(), 'day')} jours)`
                      : 'Expiré'
                    : 'Pas de date de fin'}
                </TableCell>
                <TableCell component="th" scope="row">
                  {l.usersCount}/{l.learnersMax}
                </TableCell>
                <TableCell component="th" scope="row">
                  {l.domains.map((d) => d.name).join(', ')}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  <a className={classes.linkButton} href={`/licenses/${l.id}`}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      {t('Edit')}
                    </Button>
                  </a>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteLicense(l)}
                  >
                    {t('Delete')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
