import React, { useState, useEffect, useRef } from 'react'
import './Session.scss'
import { useParams } from 'react-router-dom'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import Loader from 'react-loader-spinner'
import Content from './Content'
import { UUID } from '../../types/common'
import Correction from './Correction'
import { shuffleArray } from '../../utils'
import { Answer } from '../../types/answer'
import Button from '../../components/Button'
import ProgressBar from '../../components/ProgressBar'

export default () => {
  const [content, setContent] = useState(undefined)
  const [selectedAnswers, setSelectedAnswers] = useState<UUID[]>([])
  const { id } = useParams<any>()
  const [displayCorrection, setDisplayCorrection] = useState(false)
  const [orderedAnswers, setOrderedAnswers] = useState<Answer[]>(undefined)
  const correctionRef = useRef()
  const [validated, setValidated] = useState(undefined)

  useEffect(() => {
    id && fetchContent()
  }, [id])

  useEffect(() => {
    setDisplayCorrection(validated !== undefined)
  }, [validated])

  useEffect(() => {
    if (content?.contentType == 'sortable_question') {
      setOrderedAnswers(shuffleArray(content.answers))
    }
  }, [content])

  const fetchContent = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CONTENT({ id }))

      setContent(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve the session'))
      console.warn(e)
    }
  }

  const validateContent = async () => {
    try {
      if (content.contentType != 'sortable_question') {
        setValidated(
          JSON.stringify(selectedAnswers.sort()) ==
          JSON.stringify(
            content.answers
              .filter((a: Answer) => a.rightAnswer)
              .map((a: any) => a.id)
              .sort()
          )
        )
      } else {
        let res = true

        content.answers.forEach((a: any) => {
          if (orderedAnswers.findIndex((a2) => a2.id == a.id) != a.index) {
            res = false
          }
        })
        setValidated(res)
      }
      setTimeout(() => {
        if (correctionRef && correctionRef.current) {
          ; (correctionRef.current as any).scrollIntoView()
        }
      }, 100)
    } catch (e) {
      store.notify(Message.Error, t('Unable to validate the answer'))
      console.warn(e)
    }
  }

  const reorderAnswers = (from: number, to: number) => {
    const result = [...orderedAnswers]
    const [removed] = result.splice(from, 1)

    result.splice(to, 0, removed)
    setOrderedAnswers(result)
  }

  return (
    <div className="Session">
      <div className="Session__backgroundOverlay" />
      {content ? (
        <div className="Session__body">
          <div className="Session__header">
            <Button
              className="Session__headerBack"
              title={t('Previous')}
              onClick={undefined}
              disabled
            />
            <div className="Session__headerProgress">
              <ProgressBar
                progress={75}
                colorStart="#1A7998"
                colorEnd="#25A9D5"
              />
              {t('Simple question')}
            </div>
            <Button title={t('Leave')} dangerColor onClick={undefined} />
          </div>
          {(content.contentType != 'sortable_question' || orderedAnswers) && (
            <div
              className={`Session__content${content ? '' : ' Session__content--loading'
                }`}
            >
              <Content
                content={content}
                selectedAnswers={selectedAnswers}
                orderedAnswers={orderedAnswers}
                onAnswersReorder={reorderAnswers}
                onChange={(v) => setSelectedAnswers(v)}
                disabled={validated !== undefined}
              />
            </div>
          )}
          {displayCorrection && (
            <div className="Session__correction" ref={correctionRef}>
              <Correction
                content={content}
                selectedAnswers={selectedAnswers}
                succeeded={validated}
              />
            </div>
          )}
          <div className="Session__buttons">
            {displayCorrection ? (
              <Button title={t('Suite')} onClick={undefined} />
            ) : (
              <Button title={t('Confirm')} onClick={validateContent} />
            )}
          </div>
        </div>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
