import React, { useEffect, useState } from 'react'
import './Contents.scss'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  Typography,
  Button,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import Content from '../../types/content'
import { exportToJson } from '../../utils'
import ImportContentModal from './ImportContentModal'
import Modal from '../../components/Modal'
import humps from 'humps'
import clsx from 'clsx'
import GoodPractice from '../../types/good_practice'
import License from '../../types/license'
import Loader from 'react-loader-spinner'
import Theme from '../../types/theme'
import Domain from '../../types/domain'
const useStyles = makeStyles({
  root: {
    padding: '3em 5em',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {
    marginRight: '0.5em',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  titleElem: {
    display: 'flex',
    alignItems: 'baseline',
  },
  button: {
    marginRight: '1em',
    fontSize: '0.8em',
    textTransform: 'none',
  },
  disabled: {
    cursor: 'not-allowed',
    marginRight: '1em',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '1em',
  },
  sortable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center;',
    '& i': {
      marginLeft: '0.75em',
    },
  },
  filters: {
    display: 'flex',
    marginBottom: '2em',
  },
  filter: {
    minWidth: '15em',
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  gray: {
    '& th': {
      color: 'gray',
    },
  },
})

export default () => {
  const [assignedDomains, setAssignedDomains] = useState<any[]>(undefined)
  const [domains, setDomains] = useState<Domain[]>(undefined)
  const [themes, setThemes] = useState<Theme[]>(undefined)
  const [contents, setContents] = useState<Content[]>(undefined)
  const [licenses, setLicenses] = useState<License[]>([])
  const [goodPractices, setGoodPractices] = useState<GoodPractice[]>([])
  const [displayImportModal, setDisplayImportModal] = useState(false)
  const classes = useStyles()
  const [filters, setFilters] = useState({
    title: '',
    goodPractice: '',
    contentType: '',
    theme: '',
    domains: '',
  })
  const [orderBy, setOrderBy] = useState<{ field: string; type: string }>({
    field: undefined,
    type: undefined,
  })
  const [loadingFilter, setLoadingFiler] = useState(true)
  const [themesAvailable, setThemesAvailable] = useState<Theme[]>([])
  const [goodPracticesAvailable, setGoodPracticesAvailable] = useState<GoodPractice[]>([])
  const [contentFiltred, setContentFiltred] = useState<Content[]>([])
  // useEffect(() => {
  //   if (filters.domains === '')
  //     setThemesAvailable(void []);
  // }, [filters])

  useEffect(() => {
    setLoadingFiler(!(goodPractices && themes && domains && assignedDomains))
  }, [goodPractices, themes, domains, assignedDomains])

  useEffect(() => {
    fetchContents()
    fetchGoodPractices()
    fetchLicenses()
    fetchThemes()
    fetchAssignedDomains()
    fetchDomains()
  }, [])

  useEffect(() => {
    if (contents && domains && themes && goodPractices)
      filteredContents()
  }, [filters])

  const fetchAssignedDomains = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES_DOMAINS({ id: store.store.JWT.license_id }))
      setAssignedDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const fetchDomains = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_DOMAINS({}))
      setDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES())
      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve topics'))
      console.warn(e)
    }
  }


  const fetchContents = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CONTENTS({}))

      setContents(res)
      setContentFiltred(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve content'))
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES({}))

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const fetchGoodPractices = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICES({}))

      setGoodPractices(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve best practice')
      )
      console.warn(e)
    }
  }

  const deleteContent = async (content: Content) => {
    if (
      window.confirm(
        t(
          'Are you sure you want to delete this content? Any dependent content will also be removed.'
        )
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_CONTENT({ id: content.id }))

        setContents(contents.filter((c) => c.id != content.id))
      } catch (e) {
        store.notify(Message.Error, t('Unable to delete content'))
        console.warn(e)
      }
    }
  }

  const exportAllContents = async () => {
    try {
      const res = humps.decamelizeKeys(
        await httpClient.req(ROUTES.FETCH_CONTENTS())
      )

      exportToJson(res, 'Vigiware - contents.json')
    } catch (e) {
      store.notify(Message.Error, t("Unable to export content"))
      console.warn(e)
    }
  }

  const readableContentType = (contentType: string) => {
    switch (contentType) {
      case 'one_answer_question':
        return t('Question')
      case 'multiple_answer_question':
        return t('Question')
      case 'video':
        return t('Vidéo')
      case 'sortable_question':
        return t('Lise à ordonner')
      default:
        return t('Inconnu')
    }
  }

  const compareArray = (array1: any, item: any) => {
    array1.map((arg: any) => {
      if (arg.id === item)
        return true
    })
    return false
  }

  const DomainTopicsGoodPracticeFiltred = () => {
    let res = [...contents]
    let th = [...themes]
    let gb = [...goodPractices]
    if (filters.domains && filters.domains != '') {
      if (filters.theme && filters.theme != '') {
        th = themes.filter((t) => t.id === filters.theme)
      } else {
        th = themes.filter((t) =>
          t.domains.map((domain) => domain.id).includes(filters.domains))
        setThemesAvailable(th)
      }
      if (filters.goodPractice && filters.goodPractice != '') {
        gb = goodPractices.filter((gb) => gb.id === filters.goodPractice)
      } else {
        gb = goodPractices.filter((g) =>
          th.find((element) => element.goodPractices.find((e_gb) => e_gb.id === g.id)))
        setGoodPracticesAvailable(gb)
      }

    }
    if (filters.theme && filters.theme != '' && filters.domains === '') {
      th = themes.filter((t) => t.id === filters.theme)
      gb = goodPractices.filter((g) =>
        th.find((element) => element.goodPractices.find((e_gb) => e_gb.id === g.id)))
      setGoodPracticesAvailable(gb)
    }
    if (filters.goodPractice && filters.goodPractice != '' && (filters.domains === '' || filters.theme === '')) {
      gb = goodPractices.filter((gb) => gb.id === filters.goodPractice)
    }
    res = res.filter((r) =>
      gb.find((element) => element.contents.find((e_c) => e_c.id === r.id)))
    return res
  }

  const filteredContents = () => {
    let res = [...contents]

    if (filters.contentType && filters.contentType != '') {
      res = res.filter((c) =>
        filters.contentType == 'question'
          ? ['one_answer_question', 'multiple_answer_question'].includes(
            c.contentType
          )
          : c.contentType == filters.contentType
      )
    }
    if (filters.title && filters.title != '') {
      res = res.filter(
        (c) =>
          c.title && c.title.toLowerCase().includes(filters.title.toLowerCase())
      )
    }
    if ((filters.domains || filters.theme || filters.goodPractice) &&
      (filters.domains != '' || filters.theme != '' || filters.goodPractice != '')) {
      res = DomainTopicsGoodPracticeFiltred()
    }
    setContentFiltred(res)
  }

  return (
    <div className={classes.root}>
      {displayImportModal && (
        <Modal dismiss={() => setDisplayImportModal(false)}>
          <ImportContentModal onImported={fetchContents} />
        </Modal>
      )}
      <div className={classes.titleWrapper}>
        <div className={classes.titleElem}>
          <Typography component="h1" variant="h3" className={classes.title}>
            {t('Contents')}
          </Typography>
        </div>
        <div>
          <a className={classes.linkButton} href="/contents/new">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {t('+ Create')}
            </Button>
          </a>
          <Button
            className={classes.button}
            variant="contained"
            color="default"
            onClick={() => setDisplayImportModal(true)}
          >
            {t('Import')}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="default"
            onClick={exportAllContents}
          >
            {t('Export')}
          </Button>
        </div>
      </div>
      {loadingFilter ? null
        : (
          <div className={classes.filters}>
            <TextField
              className={classes.filter}
              label={t('Filter by questions')}
              value={filters.title}
              onChange={(e) => setFilters({ ...filters, title: e.target.value })}
            />
            <FormControl className={classes.filter}>
              <InputLabel>{t('Type of content')}</InputLabel>
              <Select
                value={filters.contentType}
                onChange={(e) =>
                  setFilters({ ...filters, contentType: e.target.value as string })
                }
              >
                <MenuItem value="">
                  <em>{t('All')}</em>
                </MenuItem>
                <MenuItem value={'question'}>{t('Question')}</MenuItem>
                <MenuItem value={'sortable_question'}>
                  {t('List to be ordered')}
                </MenuItem>
                <MenuItem value={'video'}>{t('Video')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.filter}>
              <InputLabel>{t('Domains')}</InputLabel>
              <Select
                value={filters.domains}
                onChange={(e) =>
                  setFilters({ ...filters, domains: e.target.value as string })
                }
              >
                <MenuItem value="">
                  <em>{t('All')}</em>
                </MenuItem>
                {domains.map((domain, index) => (
                  assignedDomains.find((assignedDomain) => assignedDomain === domain.id) ?
                    <MenuItem key={domain.id} value={domain.id}>
                      {domain.name}
                    </MenuItem>
                    : null
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.filter}>
              <InputLabel>{t('Topics')}</InputLabel>
              <Select
                value={filters.theme}
                onChange={(e) =>
                  setFilters({ ...filters, theme: e.target.value as string })
                }
              >
                <MenuItem value="">
                  <em>{t('All')}</em>
                </MenuItem>
                {filters.domains === '' ? (
                  themes.map((th, index) => (
                    <MenuItem key={th.id} value={th.id}>
                      {th.name}
                    </MenuItem>
                  ))
                ) : (
                  themesAvailable.map((th, index) => (
                    <MenuItem key={th.id} value={th.id}>
                      {th.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <FormControl className={classes.filter}>
              <InputLabel>{t('Best practice')}</InputLabel>
              <Select
                value={filters.goodPractice}
                onChange={(e) =>
                  setFilters({ ...filters, goodPractice: e.target.value as string })
                }
              >
                <MenuItem value="">
                  <em>{t('All')}</em>
                </MenuItem>
                {filters.domains === '' && filters.theme === '' ? (
                  goodPractices.map((gp) => (
                    <MenuItem key={gp.id} value={gp.id}>
                      {gp.name}
                    </MenuItem>
                  ))
                ) : (
                  goodPracticesAvailable.map((gp) => (
                    <MenuItem key={gp.id} value={gp.id}>
                      {gp.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>
        )}
      {contents ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx(classes.tableHeadCell)}
                  onClick={() =>
                    setOrderBy({
                      field: 'contentType',
                      type: orderBy.type == 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  <div className={clsx(classes.sortable)}>
                    {t('Type')}
                    {orderBy.field == 'contentType' && (
                      <i>
                        {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                      </i>
                    )}
                  </div>
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadCell)}
                  onClick={() =>
                    setOrderBy({
                      field: 'title',
                      type: orderBy.type == 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  <div className={clsx(classes.sortable)}>
                    {t('Content')}
                    {orderBy.field == 'title' && (
                      <i>
                        {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                      </i>
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  {t('Best practice')}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableHeadCell)}
                  onClick={() =>
                    setOrderBy({
                      field: 'points',
                      type: orderBy.type == 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  <div className={clsx(classes.sortable)}>
                    {t('Points')}
                    {orderBy.field == 'points' && (
                      <i>
                        {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                      </i>
                    )}
                  </div>
                </TableCell>
                <TableCell className={classes.tableHeadCell}>License</TableCell>
                <TableCell className={classes.tableHeadCell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(orderBy.field
                ? contentFiltred.sort((c1: any, c2: any) => {
                  let a = c1[orderBy.field]
                  let b = c2[orderBy.field]

                  try {
                    a = a.toLowerCase()
                    b = b.toLowerCase()
                  } catch { }

                  let res = a >= b ? 1 : -1

                  if (orderBy.type == 'desc') res *= -1
                  return res
                })
                : contentFiltred
              ).map((c) => (
                <TableRow
                  key={c.id}
                  className={
                    c.goodPractices.length == 0 ? classes.gray : undefined
                  }
                >
                  <TableCell component="th" scope="row">
                    {readableContentType(c.contentType)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {c.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {c.goodPractices?.map((gp) => gp.name).join(', ')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {c.points}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {licenses.find((l) => l.id == c.licenseId)?.name}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {store.store.JWT.role == 'superadmin' ? (
                      <div className={clsx(classes.centered)}>
                        <a
                          className={classes.linkButton}
                          href={`/contents/${c.id}/preview`}
                          target='_blank'
                        >
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="default"
                          >
                            <i>search</i>
                          </Button>
                        </a>
                        <a
                          className={classes.linkButton}
                          href={`/contents/${c.id}`}
                        >
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                          >
                            <i>edit</i>
                          </Button>
                        </a>
                        <a
                          className={classes.linkButton}
                          href={`/contentsD/${c.id}`}
                        >
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                          >
                            <div>duplicate</div>
                          </Button>
                        </a>
                        <a style={{ marginRight: '0' }}>

                          <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteContent(c)}
                          >
                            <i>delete</i>
                          </Button>
                        </a>
                      </div>
                    ) : (
                      c.licenseId == store.store.JWT.license_id ? (
                        <div className={clsx(classes.centered)}>
                          <a
                            className={classes.linkButton}
                            href={`/contents/${c.id}/preview`}
                            target='_blank'
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="default"
                            >
                              <i>search</i>
                            </Button>
                          </a>
                          <a
                            className={classes.linkButton}
                            href={`/contents/${c.id}`}
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                            >
                              <i>edit</i>
                            </Button>
                          </a>
                          <a
                            className={classes.disabled}
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              disabled={true}
                            >
                              <div>duplicate</div>
                            </Button>
                          </a>
                          <a style={{ marginRight: '0' }}>

                            <Button
                              className={classes.button}
                              variant="contained"
                              color="secondary"
                              onClick={() => deleteContent(c)}
                            >
                              <i>delete</i>
                            </Button>
                          </a>
                        </div>
                      ) : (
                        <div className={clsx(classes.centered)}>
                          <a
                            className={classes.linkButton}
                            href={`/contents/${c.id}/preview`}
                            target='_blank'
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="default"
                            >
                              <i>search</i>
                            </Button>
                          </a>
                          <a
                            className={classes.disabled}
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              disabled={true}
                            >
                              <i>edit</i>
                            </Button>
                          </a>
                          <a
                            className={classes.linkButton}
                            href={`/contentsD/${c.id}`}
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                            >
                              <div>duplicate</div>
                            </Button>
                          </a>
                          <a className={classes.disabled}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="secondary"
                              onClick={() => deleteContent(c)}
                              disabled={true}
                            >
                              <i>delete</i>
                            </Button>
                          </a>
                        </div>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
