import React, { useEffect, useState } from 'react'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  Typography,
  Button,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import User from '../../types/user'
import clsx from 'clsx'
import License from '../../types/license'

const useStyles = makeStyles({
  root: {
    padding: '3em 5em',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
  sortable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center;',
    '& i': {
      marginLeft: '0.75em',
    },
  },
  filters: {
    display: 'flex',
    marginBottom: '2em',
  },
  filter: {
    minWidth: '15em',
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

export default () => {
  const [users, setUsers] = useState<User[]>([])
  const [licenses, setLicenses] = useState<License[]>([])
  const classes = useStyles()
  const [filters, setFilters] = useState({
    role: '',
    license: '',
  })
  const [orderBy, setOrderBy] = useState<{ field: string; type: string }>({
    field: undefined,
    type: undefined,
  })

  useEffect(() => {
    fetchUsers()
    fetchLicences()
  }, [])

  const fetchUsers = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_USERS({}))

      setUsers(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve users'))
      console.warn(e)
    }
  }

  const fetchLicences = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES({}))

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const updateUserRole = async (user: User, role: string) => {
    try {
      const res = await httpClient.req(
        ROUTES.UPDATE_USER({ user: { ...user, role } })
      )
      const idx = users.findIndex((u) => u.id == user.id)

      users[idx] = res
      setUsers([...users])
    } catch (e) {
      store.notify(Message.Error, t('Unable to update the role'))
      console.warn(e)
    }
  }

  const deleteUser = async (user: User) => {
    if (
      window.confirm(
        t(
          'Are you sure you want to delete this user? Any dependent content will also be removed.'
        )
      )
    ) {
      try {
        await httpClient.req(ROUTES.DELETE_USER({ id: user.id }))

        setUsers(users.filter((c) => c.id != user.id))
      } catch (e) {
        store.notify(Message.Error, t("Unable to delete the user"))
        console.warn(e)
      }
    }
  }

  const filteredUsers = () => {
    let res = [...users]

    if (filters.role && filters.role != '') {
      res = res.filter(
        (u) =>
          u.role == filters.role ||
          (filters.role == 'admin' &&
            store.store.JWT.role != 'superadmin' &&
            u.role == 'supersadmin')
      )
    }
    if (filters.license && filters.license != '') {
      res = res.filter((u) => filters.license == u.licenseId)
    }
    return res
  }

  const licenseForUser = (user: User) => {
    return licenses.find((l) => l.id == user.licenseId)
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Users')}
        </Typography>
        <a className={classes.linkButton} href="/users/new">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('+ Create')}
          </Button>
        </a>
      </div>
      <div className={classes.filters}>
        <FormControl className={classes.filter}>
          <InputLabel>{t('Role')}</InputLabel>
          <Select
            value={filters.role}
            onChange={(e) =>
              setFilters({ ...filters, role: e.target.value as string })
            }
          >
            <MenuItem value="">
              <em>{t('All')}</em>
            </MenuItem>
            {store.store.JWT.role == 'superadmin' && (
              <MenuItem value={'superadmin'}>
                {t('Super administrator')}
              </MenuItem>
            )}
            <MenuItem value={'admin'}>{t('Administrator')}</MenuItem>
            <MenuItem value={'top_manager'}>{t('Top manager')}</MenuItem>
            <MenuItem value={'manager'}>{t('Manager')}</MenuItem>
            <MenuItem value={'correspondant'}>{t('Correspondent')}</MenuItem>
            <MenuItem value={'user'}>{t('User')}</MenuItem>
          </Select>
        </FormControl>
        {store.store.JWT.role == 'superadmin' && (
          <FormControl className={classes.filter}>
            <InputLabel>{t('Licenses')}</InputLabel>
            <Select
              value={filters.license}
              onChange={(e) =>
                setFilters({ ...filters, license: e.target.value as string })
              }
            >
              <MenuItem value="">
                <em>{t('All')}</em>
              </MenuItem>
              {licenses.map((l) => (
                <MenuItem value={l.id}>{l.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'name',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Name')}
                  {orderBy.field == 'name' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'email',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Email')}
                  {orderBy.field == 'email' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'role',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Role')}
                  {orderBy.field == 'role' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              {store.store.JWT.role == 'superadmin' && (
                <TableCell
                  className={clsx(classes.tableHeadCell)}
                  onClick={() =>
                    setOrderBy({
                      field: 'license',
                      type: orderBy.type == 'asc' ? 'desc' : 'asc',
                    })
                  }
                >
                  <div className={clsx(classes.sortable)}>
                    {t('License')}
                    {orderBy.field == 'license' && (
                      <i>
                        {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                      </i>
                    )}
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orderBy.field
              ? filteredUsers().sort((c1: any, c2: any) => {
                  let a =
                    orderBy.field == 'name'
                      ? `${c1.firstName} ${c1.lastName}`
                      : orderBy.field == 'license'
                      ? licenses.find((l) => l.id == c1.licenseId)?.name
                      : c1[orderBy.field]
                  let b =
                    orderBy.field == 'name'
                      ? `${c2.firstName} ${c2.lastName}`
                      : orderBy.field == 'license'
                      ? licenses.find((l) => l.id == c2.licenseId)?.name
                      : c2[orderBy.field]

                  try {
                    a = a.toLowerCase()
                    b = b.toLowerCase()
                  } catch {}

                  let res = a >= b ? 1 : -1

                  if (orderBy.type == 'desc') res *= -1
                  return res
                })
              : filteredUsers()
            ).map((u) => (
              <TableRow key={u.id}>
                <TableCell component="th" scope="row">
                  {u.firstName} {u.lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {u.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Select
                    value={
                      store.store.JWT.role != 'superadmin' &&
                      u.role == 'superadmin'
                        ? 'admin'
                        : u.role || ''
                    }
                    onChange={(e) =>
                      updateUserRole(u, e.target.value as string)
                    }
                  >
                    <MenuItem key={'user'} value={'user'}>
                      {t('User')}
                    </MenuItem>
                    <MenuItem key={'correspondant'} value={'correspondant'}>
                      {t('Correspondent')}
                    </MenuItem>
                    <MenuItem key={'manager'} value={'manager'}>
                      {t('Manager')}
                    </MenuItem>
                    <MenuItem key={'top_manager'} value={'top_manager'}>
                      {t('Top manager')}
                    </MenuItem>
                    <MenuItem key={'admin'} value={'admin'}>
                      {t('Administrator')}
                    </MenuItem>
                    {store.store.JWT.role == 'superadmin' && (
                      <MenuItem key={'superadmin'} value={'superadmin'}>
                        {t('Super administrator')}
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
                {store.store.JWT.role == 'superadmin' && (
                  <TableCell component="th" scope="row">
                    {licenses.find((l) => l.id == u.licenseId)?.name}
                  </TableCell>
                )}
                <TableCell align="right" component="th" scope="row">
                  <a className={classes.linkButton} href={`/users/${u.id}`}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      {t('Edit')}
                    </Button>
                  </a>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteUser(u)}
                  >
                    {t('Delete')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
