import React, { useState, useCallback, Fragment, useEffect } from 'react'
import { TextField, Select, MenuItem, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Language from '../../types/language'
import { UUID } from '../../types/common'
import { exec as pellExec, init, exec } from 'pell'
import { render } from 'react-dom'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  languageSelector: {
    marginRight: '1em',
  },
  language: {
    height: '2em',
    width: '2em',
    objectFit: 'cover',
  },
})

interface Props {
  value: any
  translationKey: string
  label: string
  className?: string
  languages: Language[]
  multiline?: boolean
  wysiwyg?: boolean
  onChange: (value: any) => void
  language?: Language
  onLanguageChange?: (id: string) => void
}

export default (props: Props) => {
  const [language, setLanguage] = useState<UUID>(
    props.language?.id || props.languages[0].id
  )
  const value = props.value.find(
    (v: any) => v.locale == props.languages.find((l) => l.id == language)?.code
  )
  const classes = useStyles()
  const [editor, setEditor] = useState<any>(undefined)
  const [editorContent, setEditorContent] = useState<string>(undefined)
  const [_, setLanguageWrapperId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (editorContent) onChange(editorContent)
  }, [editorContent])

  useEffect(() => {
    if (editor) {
      refreshPell()
      editor.content.innerHTML = value ? value[props.translationKey] || '' : ''
    }
  }, [language])

  useEffect(() => {
    if (props.language) {
      setLanguage(props.language.id)
    }
  }, [props.language])

  const refreshPell = () => {
    const elang = document.querySelector('#EditorLanguages')

    elang.innerHTML = `<img src=${
      props.languages.find((l) => l.id == language)?.imageUrl
    } />`
  }

  const changeLanguage = (id: UUID) => {
    if (props.onLanguageChange) {
      props.onLanguageChange(id)
    } else {
      setLanguage(id)
    }
  }

  const toggleLanguagesMenu = () => {
    setLanguageWrapperId((languageWrapperId) => {
      const currWrapper =
        languageWrapperId && document.querySelector(`#${languageWrapperId}`)

      if (!currWrapper) {
        const id = `a${Math.random().toString(36).substring(7)}`
        const t = document.createElement('div')
        const elang = document.querySelector('#EditorLanguages')

        t.id = id
        elang && elang.appendChild(t)
        render(
          props.languages.map((l) => (
            <div key={l.id} onClick={() => changeLanguage(l.id)}>
              <img src={l.imageUrl} />
            </div>
          )),
          t
        )
        return id
      }
      currWrapper.remove()
      return undefined
    })
  }

  const editorBtns = [
    {
      name: 'languages',
      icon: `<div id="EditorLanguages" class="Editor__languages"><img src=${
        props.languages.find((l) => l.id == language)?.imageUrl
      }/></div>`,
      result: toggleLanguagesMenu,
      title: 'Languages',
    },
    'bold',
    'underline',
    'strikethrough',
    'heading1',
    'heading2',
    {
      name: 'italic',
      result: () =>
        document.execCommand(
          'insertHtml',
          false,
          '<em>' + document.getSelection() + '</em>'
        ),
    },
    'paragraph',
    'quote',
    {
      name: 'olist',
      icon: '<i class="material-icons">format_list_numbered</i>',
    },
    {
      name: 'ulist',
      icon: '<i class="material-icons">format_list_bulleted</i>',
    },
    'line',
    { name: 'link', icon: '<i class="material-icons">insert_link</i>' },
    {
      name: 'removeFormat',
      icon: '<i class="material-icons">format_clear</i>',
      title: 'Remove format',
      result: () => pellExec('removeFormat'),
    },
  ]

  const refreshEditorContent = (html: string) => {
    setEditorContent(html)
  }

  const pellRef = useCallback((input: HTMLDivElement) => {
    if (input !== null) {
      const e = init({
        onChange: refreshEditorContent,
        element: input,
        actions: editorBtns as any,
      })

      e.content.innerHTML = value ? value[props.translationKey] || '' : ''
      setEditor(e)
    }
  }, [])

  const onChange = (v: string) => {
    let tmpValue: any[] = [...props.value]
    let locale = props.languages.find((l) => l.id == language)?.code
    let idx = tmpValue.findIndex((v) => v.locale == locale)

    if (idx == -1) {
      idx = tmpValue.length
      tmpValue.push({ locale })
    }

    tmpValue[idx][props.translationKey] = v
    props.onChange(tmpValue)
  }

  return (
    <div className={clsx(props.className, classes.root)}>
      {props.wysiwyg ? (
        <div className="pell-editor" ref={pellRef} />
      ) : (
        <Fragment>
          <Select
            className={classes.languageSelector}
            value={language || ''}
            onChange={(v) => {
              changeLanguage(v.target.value as UUID)
            }}
            renderValue={(v: UUID) => (
              <div>
                <img
                  className={classes.language}
                  src={props.languages.find((l) => l.id == v).imageUrl}
                />
              </div>
            )}
          >
            {props.languages.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                <img className={classes.language} src={l.imageUrl} />
              </MenuItem>
            ))}
          </Select>
          <TextField
            required
            fullWidth
            multiline={props.multiline}
            label={props.label}
            value={value ? value[props.translationKey] || '' : ''}
            onChange={(e) => onChange(e.target.value)}
          />
        </Fragment>
      )}
    </div>
  )
}
