import React, { useState, useEffect } from 'react'
import Theme from '../../../types/theme'
import { RouteComponentProps, useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  Chip,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core'
import './ThemeForm.scss'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'
import GoodPractice from '../../../types/good_practice'
import TranslatableTextField from '../../../components/TranslatableTextField'
import Language from '../../../types/language'
import clsx from 'clsx'
import Domain from '../../../types/domain'

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    themeImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    themeImageTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    themeImageFile: {
      marginBottom: '1em',
    },
  })
)

export default () => {
  const [theme, setTheme] = useState<Theme>(undefined)
  const [goodPractices, setGoodPractices] = useState<GoodPractice[]>([])
  const [licenses, setLicenses] = useState<License[]>([])
  const [selectedGoodPractices, setSelectedGoodPractices] = useState<UUID[]>([])
  const [selectedDomains, setSelectedDomains] = useState<UUID[]>([])
  const [domains, setDomains] = useState<Domain[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [actualLanguage, setActualLanguage] = useState<Language>(undefined)
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (theme) {
      setSelectedGoodPractices(
        selectedGoodPractices.filter((gp) => {
          const goodPractice = goodPractices.find((gp2) => gp2.id == gp)

          return goodPractice
            ? !goodPractice.licenseId ||
            goodPractice.licenseId == theme.licenseId
            : false
        })
      )
    }
  }, [theme?.licenseId, goodPractices])

  useEffect(() => {
    if (!actualLanguage && languages.length > 0) {
      setActualLanguage(languages.find((l) => l.code == 'fr') || languages[0])
    }
  }, [languages])

  const fetchData = async () => {
    fetchLicenses()
    fetchLanguages()
    await fetchGoodPractices()
    await fetchDomains()
    await fetchTheme()
  }

  const fetchTheme = async () => {
    if (!id) {
      setTheme({} as Theme)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_THEME({ id }))

        setTheme(res)
        setSelectedGoodPractices(
          res.goodPractices?.map((gp: any) => gp.id) || []
        )
        setSelectedDomains(res.domains?.map((d: any) => d.id) || [])
      } catch (e) {
        store.notify(Message.Error, t('Unable to retrieve best practice'))
        console.warn(e)
      }
    }
  }

  const fetchGoodPractices = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICES({}))

      setGoodPractices(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve best practice')
      )
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES())

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const fetchDomains = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_DOMAINS())

      setDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const submitTheme = async () => {
    let route = id ? ROUTES.UPDATE_THEME : ROUTES.CREATE_THEME

    try {
      const res = await httpClient.req(
        route({
          theme: {
            ...theme,
            keywords:
              theme.keywords?.map((k) => k.trim()).filter((k) => k != '') || [],
            selectedGoodPractices,
            selectedDomains,
          },
        })
      )

      store.notify(
        Message.Notification,
        t('Best practice successfully saved')
      )
      setTheme(res)
      history.push('/themes')
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to save the best practice')
      )
      console.warn(e)
    }
  }

  const updateImage = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      setTheme({ ...theme, imageUrl: res.imageUrl })
    } catch (e) {
      store.notify(Message.Error, t("Unable to update the image"))
      console.warn(e)
    }
  }

  const updatePdf = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      const indexPDF = theme.themeTranslations.findIndex((translation) => translation.locale === actualLanguage.code)
      setTheme({
        ...theme,
        themeTranslations: theme.themeTranslations.map((element, index) => {
          if (index === indexPDF) {
            element.pdfUrl = res.imageUrl
          }
          return element
        })
      })
    } catch (e) {
      store.notify(Message.Error, t('Unable to update the PDF'))
      console.warn(e)
    }
  }

  const canBeSubmitted = () => {
    const hasNotEmptyName = !!theme.themeTranslations?.find(
      (tt) => tt.name && tt.name.length > 0
    )

    return hasNotEmptyName
  }

  const getDataLanguage = () => {
    if (theme.themeTranslations) {
      const data = theme.themeTranslations.find((translation) => translation.locale === actualLanguage.code)
      return data
    }
    return null
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title} onClick={() => console.log(theme)}>
        {id ? t('Edit') : t('Create')} {t('a topic')}
      </Typography>
      {theme && languages.length > 0 ? (
        <form onSubmit={submitTheme}>
          <FormControl className={classes.formControl}>
            <TranslatableTextField
              className={classes.inputField}
              value={theme.themeTranslations || []}
              translationKey="name"
              label={t('Name')}
              onChange={(v) =>
                setTheme({
                  ...theme,
                  themeTranslations: v,
                })
              }
              languages={languages}
              language={actualLanguage}
              onLanguageChange={(id) => {
                setActualLanguage(languages.find((l) => l.id == id))
              }
              }
            />
            <TranslatableTextField
              className={classes.inputField}
              value={theme.themeTranslations || []}
              translationKey="description"
              label={t('Description')}
              multiline
              onChange={(v) =>
                setTheme({
                  ...theme,
                  themeTranslations: v,
                })
              }
              languages={languages}
              language={actualLanguage}
              onLanguageChange={(id) =>
                setActualLanguage(languages.find((l) => l.id == id))
              }
            />
            <Typography component="h2" variant="h6" className={classes.title}>
              {t('Image')}
            </Typography>
            <div className={clsx(classes.themeImage, classes.inputField)}>
              {theme.imageUrl && (
                <img src={theme.imageUrl} className={classes.themeImageTag} />
              )}
              <input
                type="file"
                className={classes.themeImageFile}
                onChange={(e) => updateImage(e.target.files[0])}
                accept="image/jpg, image/jpeg, image/png"
              />
            </div>
            <Typography component="h2" variant="h6" className={classes.title}>
              {t('PDF')}
            </Typography>
            <div className={clsx(classes.themeImage, classes.inputField)}>
              {getDataLanguage() ? (
                <embed
                  src={getDataLanguage().pdfUrl}
                  className={classes.inputField}
                  width="350"
                  height="500px"
                />
              ) : null}
              <input
                type="file"
                className={classes.themeImageFile}
                onChange={(e) => updatePdf(e.target.files[0])}
              />
            </div>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('Keywords (separated by a comma)')}
              className={classes.inputField}
              value={theme.keywords}
              onChange={(e) =>
                setTheme({
                  ...theme,
                  keywords: e.target.value.split(','),
                })
              }
            />
            {store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('Licenses')}</InputLabel>
                <Select
                  value={theme?.licenseId || ''}
                  onChange={(v) => {
                    setTheme({ ...theme, licenseId: v.target.value as UUID })
                  }}
                  className={classes.inputField}
                >
                  <MenuItem key="all" value={''}>
                    {t('No license attached')}
                  </MenuItem>
                  {licenses
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        {l.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Best practice')}</InputLabel>
              <Select
                value={selectedGoodPractices}
                multiple
                onChange={(v) => {
                  setSelectedGoodPractices(v.target.value as UUID[])
                }}
                className={classes.inputField}
                renderValue={(v: string[]) => (
                  <div>
                    {v.map((value) => (
                      <Chip
                        key={value}
                        label={goodPractices.find((gp) => gp.id == value)?.name}
                      />
                    ))}
                  </div>
                )}
              >
                {goodPractices
                  .filter(
                    (gp) =>
                      !gp.licenseId ||
                      gp.licenseId ==
                      (store.store.JWT.role == 'superadmin'
                        ? theme.licenseId
                        : store.store.JWT.license_id)
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((t) => (
                    <MenuItem key={t.id} value={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {store.store.JWT && store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('Domains')}</InputLabel>
                <Select
                  value={selectedDomains}
                  onChange={(v) => {
                    setSelectedDomains(v.target.value as UUID[])
                  }}
                  className={classes.inputField}
                  multiple
                >
                  {domains
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={submitTheme}
              disabled={!canBeSubmitted()}
            >
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
