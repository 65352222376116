import React, { useState } from 'react'
import './Layout.scss'
import { makeStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Themes from '../Themes'
import { useForceUpdate } from '../../utils'
import store, { Stored, Message } from '../../services/store'
import Toastify from 'toastify-js'
import Login from '../Login'
import GoodPractices from '../GoodPractices'
import Contents from '../Contents'
import Licenses from '../Licenses'
import { t } from '../../services/i18n'
import GoodPracticeForm from '../GoodPractices/GoodPracticeForm'
import ThemeForm from '../Themes/ThemeForm'
import ContentForm from '../Contents/ContentForm'
import Users from '../Users'
import UserForm from '../Users/UserForm'
import LicenseForm from '../Licenses/LicenseForm'
import CampaignTemplates from '../CampaignTemplates'
import CampaignTemplateForm from '../CampaignTemplates/CampaignTemplateForm'
import Languages from '../Languages'
import LanguageForm from '../Languages/LanguageForm'
import Session from '../Session'
import DomainForm from '../Domains/DomainForm'
import Domains from '../Domains'

const drawerWidth = 275

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  link: {
    textDecoration: 'none',
    color: 'unset',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: { ...theme.mixins.toolbar, flexShrink: 0, width: '100%' },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100vh',
  },
  contentBody: { overflow: 'auto', width: '100%' },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  logout: {
    textAlign: 'center',
    color: '#fc7169',
  },
}))

export default () => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const [loggedIn, setLoggedIn] = useState(store.store.RawJWT)
  const forceUpdate = useForceUpdate()

  store.listen(Stored.Language, forceUpdate)
  store.listen(Stored.RawJWT, setLoggedIn)
  store.listen(Message.Error, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#fc7169',
    }).showToast()
  )
  store.listen(Message.Notification, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#4dce76',
    }).showToast()
  )
  store.listen(Message.NeedAuth, () => {
    store.update(Stored.JWT, undefined)
    store.update(Stored.RawJWT, undefined)
    store.update(Stored.RefreshToken, undefined)
  })

  return (
    <BrowserRouter>
      {loggedIn ? (
        <div className={clsx('Layout', classes.root)}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {t('Dashboard')}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
          >
            <div className={classes.toolbarIcon}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(false)}
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <a className={classes.link} href="/contents">
                <ListItem button>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Contents')} />
                </ListItem>
              </a>
              <a className={classes.link} href="/good_practices">
                <ListItem button>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Best practice')} />
                </ListItem>
              </a>
              <a className={classes.link} href="/themes">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Topics')} />
                </ListItem>
              </a>
              {store.store.JWT.role == 'superadmin' && (
                <a className={classes.link} href="/domains">
                  <ListItem button>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Domains')} />
                  </ListItem>
                </a>
              )}
              {store.store.JWT.role == 'superadmin' && (
                <a className={classes.link} href="/licenses">
                  <ListItem button>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Licenses')} />
                  </ListItem>
                </a>
              )}
              <a className={classes.link} href="/users">
                <ListItem button>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Users')} />
                </ListItem>
              </a>
              <a className={classes.link} href="/campaign_templates">
                <ListItem button>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Training models')} />
                </ListItem>
              </a>
              {store.store.JWT.role == 'superadmin' && (
                <a className={classes.link} href="/languages">
                  <ListItem button>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Language')} />
                  </ListItem>
                </a>
              )}
              <ListItem
                button
                className={classes.logout}
                onClick={() => store.notify(Message.NeedAuth)}
              >
                <ListItemText primary={t('Disconnect')} />
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.contentBody}>
              <Switch>
                <Route path="/languages/new">
                  <LanguageForm />
                </Route>
                <Route path="/languages/:id">
                  <LanguageForm />
                </Route>
                <Route path="/languages">
                  <Languages />
                </Route>
                <Route path="/contents/:id/preview">
                  <Session />
                </Route>
                <Route path="/contents/new">
                  <ContentForm duplicateMod={false} />
                </Route>
                <Route path="/contents/:id">
                  <ContentForm duplicateMod={false} />
                </Route>
                <Route path="/contentsD/:id">
                  <ContentForm duplicateMod={true} />
                </Route>
                <Route path="/contents">
                  <Contents />
                </Route>
                <Route path="/domains/new">
                  <DomainForm />
                </Route>
                <Route path="/domains/:id">
                  <DomainForm />
                </Route>
                <Route path="/domains">
                  <Domains />
                </Route>
                <Route path="/themes/new">
                  <ThemeForm />
                </Route>
                <Route path="/themes/:id">
                  <ThemeForm />
                </Route>
                <Route path="/themes">
                  <Themes />
                </Route>
                <Route path="/good_practices/new">
                  <GoodPracticeForm />
                </Route>
                <Route path="/good_practices/:id">
                  <GoodPracticeForm />
                </Route>
                <Route path="/good_practices">
                  <GoodPractices />
                </Route>
                <Route path="/licenses/new">
                  <LicenseForm />
                </Route>
                <Route path="/licenses/:id">
                  <LicenseForm />
                </Route>
                <Route path="/licenses">
                  <Licenses />
                </Route>
                <Route path="/users/new">
                  <UserForm />
                </Route>
                <Route path="/users/:id">
                  <UserForm />
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/campaign_templates/new">
                  <CampaignTemplateForm duplicateMod={false} />
                </Route>
                <Route path="/campaign_templates/duplicate/:id">
                  <CampaignTemplateForm duplicateMod={true} />
                </Route>
                <Route path="/campaign_templates/:id">
                  <CampaignTemplateForm duplicateMod={false} />
                </Route>
                <Route path="/campaign_templates">
                  <CampaignTemplates />
                </Route>
                <Route path="/">
                  <Contents />
                </Route>
              </Switch>
            </div>
          </main>
        </div>
      ) : (
        <Login />
      )}
    </BrowserRouter>
  )
}
