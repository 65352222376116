import React from 'react'
import './Answer.scss'

interface Props {
  singleSelection?: boolean
  title: string
  value: any
  selected?: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  reorderable?: boolean
}

export default (props: Props) => {
  return (
    <div
      className={`Answer${
        props.disabled
          ? ' Answer--disabled'
          : props.reorderable
          ? ' Answer--reorderable'
          : ''
      }`}
      onClick={
        props.disabled
          ? undefined
          : () =>
              props.singleSelection
                ? props.onChange(true)
                : props.onChange(!props.selected)
      }
    >
      {props.reorderable ? (
        <i>reorder</i>
      ) : (
        <div
          className={`Answer__checkbox${
            props.singleSelection ? ' Answer__checkbox--singleSelection' : ''
          }${props.selected ? ' Answer__checkbox--selected' : ''}`}
        >
          {props.selected && <div className="Answer__checkboxContent" />}
        </div>
      )}
      <div className="Answer__title">{props.title}</div>
    </div>
  )
}
