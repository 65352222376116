import { UUID } from './types/common';
import GoodPractice from './types/good_practice';
import Theme from './types/theme';
import Content from './types/content';
import User from './types/user';
import License from './types/license';
import CampaignTemplate from './types/campaign_template';
import Language from './types/language';
import Domain from './types/domain';
// import * as dotenv from 'dotenv'

export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string;
  method: Method;
  data?: any;
  params?: any;
  auth?: boolean;
  file?: File;
  extern?: boolean;
  skipHumps?: boolean;
  headers?: any;
}

// dotenv.config();
export const BASE_URL: string = process.env.BASE_URL_API as string; //'https://api.vigiware.com/' // 'http://localhost:3000' //

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  LOGIN: (data: { email: string; password: string }) => ({
    path: '/admins/login',
    method: Method.Post,
    auth: false,
    data,
  }),
  FETCH_LANGUAGES: () => ({
    path: '/languages',
    method: Method.Get,
    auth: true,
  }),
  FETCH_LANGUAGE: (data: { id: UUID }) => ({
    path: `/languages/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  DELETE_LANGUAGE: (data: { id: UUID }) => ({
    path: `/languages/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  CREATE_LANGUAGE: (data: { language: Language }) => ({
    path: `/languages`,
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_LANGUAGE: (data: { language: Language }) => ({
    path: `/languages/${data.language.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_USERS: () => ({
    path: '/users',
    method: Method.Get,
    auth: true,
  }),
  FETCH_THEMES: () => ({
    path: '/themes',
    method: Method.Get,
    auth: true,
  }),
  FETCH_DOMAINS: () => ({
    path: '/domains',
    method: Method.Get,
    auth: true,
  }),
  FETCH_THEME: (data: { id: string }) => ({
    path: `/themes/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_DOMAIN: (data: { id: string }) => ({
    path: `/domains/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  DELETE_DOMAIN: (data: { id: UUID }) => ({
    path: `/domains/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  FETCH_GOOD_PRACTICES: () => ({
    path: '/good_practices',
    method: Method.Get,
    auth: true,
  }),
  FETCH_GOOD_PRACTICE: (data: { id: string }) => ({
    path: `/good_practices/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CONTENTS: () => ({
    path: '/contents',
    method: Method.Get,
    auth: true,
  }),
  FETCH_CONTENT: (data: { id: string }) => ({
    path: `/contents/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_LICENSES: () => ({
    path: '/licenses',
    method: Method.Get,
    auth: true,
  }),
  FETCH_LICENSES_DOMAINS: (data: { id: UUID }) => ({
    path: `/licenses/domains/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_GOOD_PRACTICE: (
    data: { goodPractice: GoodPractice } & { selectedThemes: UUID[] }
  ) => ({
    path: '/good_practices',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_GOOD_PRACTICE: (
    data: { goodPractice: GoodPractice } & { selectedThemes: UUID[] }
  ) => ({
    path: `/good_practices/${data.goodPractice.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  CREATE_THEME: (
    data: { theme: Theme } & { selectedGoodPractices: UUID[] }
  ) => ({
    path: '/themes',
    method: Method.Post,
    auth: true,
    data,
    file: data.theme.imageFile,
  }),
  UPDATE_THEME: (
    data: { theme: Theme } & { selectedGoodPractices: UUID[] }
  ) => ({
    path: `/themes/${data.theme.id}`,
    method: Method.Patch,
    auth: true,
    data,
    file: data.theme.imageFile,
  }),
  CREATE_DOMAIN: (
    data: { domain: Domain } & { selectedThemes: UUID[] }
  ) => ({
    path: '/domains',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_DOMAIN: (
    data: { domain: Domain } & { selectedThemes: UUID[] }
  ) => ({
    path: `/domains/${data.domain.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPLOAD_FILE: (data: { file: File }) => ({
    path: `/uploads`,
    method: Method.Post,
    auth: true,
    file: data.file,
  }),
  CREATE_CONTENT: (
    data: { content: Content } & { selectedGoodPractices: UUID[] }
  ) => ({
    path: '/contents',
    method: Method.Post,
    auth: true,
    data,
  }),
  UPDATE_CONTENT: (
    data: { content: Content } & { selectedGoodPractices: UUID[] }
  ) => ({
    path: `/contents/${data.content.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  DELETE_CONTENT: (data: { id: UUID }) => ({
    path: `/contents/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_THEME: (data: { id: UUID }) => ({
    path: `/themes/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  DELETE_GOOD_PRACTICE: (data: { id: UUID }) => ({
    path: `/good_practices/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  FETCH_USER: (data: { id: UUID }) => ({
    path: `/users/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_ME: () => ({
    path: '/users/me',
    method: Method.Get,
    auth: true,
  }),
  CREATE_USER: (
    data: { user: User } & { selectedGoodPractices: UUID[] }
  ) => ({
    path: '/users',
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_USER: (data: { id: UUID }) => ({
    path: `/users/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_USER: (data: { user: User }) => ({
    path: `/users/${data.user.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_LICENSE: (data: { id: UUID }) => ({
    path: `/licenses/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_LICENSE: (data: { license: License }) => ({
    path: '/licenses',
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_LICENSE: (data: { id: UUID }) => ({
    path: `/licenses/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_LICENSE: (data: { license: License }) => ({
    path: `/licenses/${data.license.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_CAMPAIGN_TEMPLATES: () => ({
    path: `/campaign_templates`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGN_TEMPLATE: (data: { id: UUID }) => ({
    path: `/campaign_templates/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  CREATE_CAMPAIGN_TEMPLATE: (data: {
    campaignTemplate: CampaignTemplate;
  }) => ({
    path: '/campaign_templates',
    method: Method.Post,
    auth: true,
    data,
  }),
  DELETE_CAMPAIGN_TEMPLATE: (data: { id: UUID }) => ({
    path: `/campaign_templates/${data.id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_CAMPAIGN_TEMPLATE: (data: {
    campaignTemplate: CampaignTemplate;
  }) => ({
    path: `/campaign_templates/${data.campaignTemplate.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  EXPORT_ALL_CONTENTS: () => ({
    path: `/contents/export`,
    method: Method.Get,
    auth: true,
  }),
  IMPORT_CONTENTS: (data: { file: File }) => ({
    path: `/contents/import`,
    method: Method.Post,
    auth: true,
    file: data.file,
  }),
};

export default ROUTES;
