import { useState, useRef, useEffect } from 'react'

export const useForceUpdate = () => {
  const [value, setValue] = useState(0)

  return () => setValue((value) => ++value)
}

export const usePrevious = (value: any) => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const exportToJson = (objectData: any, name?: string) => {
  const filename = name || 'export.json'
  const contentType = 'application/json;charset=utf-8;'

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
      { type: contentType }
    )
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    var a = document.createElement('a')
    a.download = filename
    a.href =
      'data:' +
      contentType +
      ',' +
      encodeURIComponent(JSON.stringify(objectData))
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const shuffleArray = (arr: any[]) =>
  arr
    .map((a) => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1])
