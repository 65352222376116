import React, { useEffect, useState } from 'react'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography, Button, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Language from '../../types/language'
import './Languages.scss'

const useStyles = makeStyles({
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  flagIcon: {
    width: '2em',
    height: '2em',
    objectFit: 'cover',
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
})

export default () => {
  const [languages, setLanguages] = useState<Language[]>([])
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchLanguages()
  }, [])

  const fetchLanguages = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LANGUAGES({}))

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const deleteLanguage = async (language: Language) => {
    if (window.confirm(t('Êtes-vous certain de vouloir supprimer cette ?'))) {
      try {
        await httpClient.req(ROUTES.DELETE_LANGUAGE({ id: language.id }))

        setLanguages(languages.filter((l) => l.id != language.id))
      } catch (e) {
        store.notify(Message.Error, t('Unable to remove the language'))
        console.warn(e)
      }
    }
  }

  return (
    <div className="Languages">
      <div className={classes.titleWrapper}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Language')}
        </Typography>
        <a className={classes.linkButton} href="/languages/new">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('+ Create')}
          </Button>
        </a>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}></TableCell>
              <TableCell className={classes.tableHeadCell}>
                {t('Language')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {languages.map((language) => (
              <TableRow key={language.id}>
                <TableCell scope="row">
                  <img src={language.imageUrl} className={classes.flagIcon} />
                </TableCell>
                <TableCell scope="row">{language.name}</TableCell>
                <TableCell align="right" scope="row">
                  <div className={classes.centered}>
                    <a
                      className={classes.linkButton}
                      href={`/languages/${language.id}`}
                    >
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                      >
                        {t('Edit')}
                      </Button>
                    </a>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteLanguage(language)}
                    >
                      {t('Delete')}
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
