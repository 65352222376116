import React, { useEffect, useState } from 'react'
import './GoodPractices.scss'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  Typography,
  Button,
  makeStyles,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core'
import GoodPractice from '../../types/good_practice'
import { useHistory } from 'react-router-dom'
import Theme from '../../types/theme'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    padding: '3em 5em',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
  sortable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center;',
    '& i': {
      marginLeft: '0.75em',
    },
  },
  filters: {
    display: 'flex',
    marginBottom: '2em',
  },
  filter: {
    minWidth: '15em',
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

export default () => {
  const [goodPractices, setGoodPractices] = useState<GoodPractice[]>([])
  const [themes, setThemes] = useState<Theme[]>([])
  const classes = useStyles()
  const [filters, setFilters] = useState({
    name: '',
    theme: '',
    criticity: undefined,
    keyword: '',
  })
  const [orderBy, setOrderBy] = useState<{ field: string; type: string }>({
    field: undefined,
    type: undefined,
  })

  useEffect(() => {
    fetchGoodPractices()
    fetchThemes()
  }, [])

  const fetchGoodPractices = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICES({}))

      setGoodPractices(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve best practice')
      )
      console.warn(e)
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES({}))

      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve topics'))
      console.warn(e)
    }
  }

  const deleteGoodPractice = async (goodPractice: GoodPractice) => {
    if (
      window.confirm(
        t(
          'Are you sure you want to remove this best practice? Any dependent content will also be removed.'
        )
      )
    ) {
      try {
        await httpClient.req(
          ROUTES.DELETE_GOOD_PRACTICE({ id: goodPractice.id })
        )

        setGoodPractices(goodPractices.filter((c) => c.id != goodPractice.id))
      } catch (e) {
        store.notify(
          Message.Error,
          t('Unable to remove the best practice')
        )
        console.warn(e)
      }
    }
  }

  const filteredGoodPractices = () => {
    let res = [...goodPractices]

    if (filters.keyword && filters.keyword != '') {
      res = res.filter((gp) => {
        let res = false

        gp.keywords.forEach((k) => {
          if (k.toLowerCase().includes(filters.keyword.toLowerCase())) {
            res = true
          }
        })
        return res
      })
    }
    if (filters.name && filters.name != '') {
      res = res.filter((gp) =>
        gp.name.toLowerCase().includes(filters.name.toLowerCase())
      )
    }
    if (filters.criticity && filters.criticity != '') {
      res = res.filter((gp) => gp.criticity == filters.criticity)
    }
    if (filters.theme && filters.theme != '') {
      res = res.filter((gp) =>
        gp.themes.map((t) => t.id).includes(filters.theme)
      )
    }
    return res
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Best practice')}
        </Typography>
        <a className={classes.linkButton} href="/good_practices/new">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('+ Create')}
          </Button>
        </a>
      </div>
      <div className={classes.filters}>
        <TextField
          className={classes.filter}
          label={t('Filter by name')}
          value={filters.name}
          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
        />
        <TextField
          className={classes.filter}
          label={t('Filter by keyword')}
          value={filters.keyword}
          onChange={(e) => setFilters({ ...filters, keyword: e.target.value })}
        />
        <TextField
          className={classes.filter}
          label={t('Filter by criticity')}
          value={filters.criticity}
          onChange={(e) =>
            setFilters({ ...filters, criticity: +e.target.value })
          }
          type="number"
        />
        <FormControl className={classes.filter}>
          <InputLabel>{t('Topic')}</InputLabel>
          <Select
            value={filters.theme}
            onChange={(e) =>
              setFilters({ ...filters, theme: e.target.value as string })
            }
          >
            <MenuItem value="">
              <em>{t('All')}</em>
            </MenuItem>
            {themes.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'name',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Best practice')}
                  {orderBy.field == 'name' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'codification',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Codification')}
                  {orderBy.field == 'codification' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell
                className={clsx(classes.tableHeadCell)}
                onClick={() =>
                  setOrderBy({
                    field: 'criticity',
                    type: orderBy.type == 'asc' ? 'desc' : 'asc',
                  })
                }
              >
                <div className={clsx(classes.sortable)}>
                  {t('Criticality')}
                  {orderBy.field == 'criticity' && (
                    <i>
                      {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                    </i>
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Themes</TableCell>
              <TableCell className={classes.tableHeadCell}>Keywords</TableCell>
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orderBy.field
              ? filteredGoodPractices().sort((t1: any, t2: any) => {
                let a = t1[orderBy.field]
                let b = t2[orderBy.field]

                try {
                  a = a.toLowerCase()
                  b = b.toLowerCase()
                } catch { }

                let res = a >= b ? 1 : -1

                if (orderBy.type == 'desc') res *= -1
                return res
              })
              : filteredGoodPractices()
            ).map((gp) => (
              <TableRow key={gp.id}>
                <TableCell component="th" scope="row">
                  {gp.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {gp.codification}
                </TableCell>
                <TableCell component="th" scope="row">
                  {gp.criticity}
                </TableCell>
                <TableCell component="th" scope="row">
                  {gp.themes.map((theme) => theme.name).join(', ')}
                </TableCell>
                <TableCell component="th" scope="row">
                  {gp.keywords.join(', ')}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {store.store.JWT.role == 'superadmin' ||
                    gp.licenseId == store.store.JWT.license_id ? (
                    <div className={classes.centered}>
                      <a
                        className={classes.linkButton}
                        href={`/good_practices/${gp.id}`}
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                        >
                          {t('Edit')}
                        </Button>
                      </a>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteGoodPractice(gp)}
                      >
                        {t('Delete')}
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.centered}>
                      {t('Shared content')}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
