import React, { useState, useEffect, SetStateAction } from 'react'
import CampaignTemplate from '../../../types/campaign_template'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  MenuItem,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
  Paper,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'
import TranslatableTextField from '../../../components/TranslatableTextField'
import Language from '../../../types/language'
import Content from '../../../types/content'
import Theme from '../../../types/theme'
import GoodPractice from '../../../types/good_practice'
import clsx from 'clsx'
import { usePrevious } from '../../../utils'
import Domain from '../../../types/domain'
import './CamapaignTemplateForm.scss'
import PlusToMinus from '../../../components/PlusToMinus'
const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    contentEntry: {
      display: 'flex',
      alignItems: 'center',
    },
    removeContent: {
      marginLeft: '0.25em',
      fontSize: '1.25em',
      color: '#d53a3a',
      cursor: 'pointer',
    },
    filtersEntry: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5em',
      width: '40em',
    },
    contents: {
      padding: '2em',
      marginBottom: '2em',
    },
    accordionContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    accordion: {
      marginBottom: '1em',
    },
    templateImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    templateImageTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    templateImageFile: {
      marginBottom: '1em',
    },
    errorHint: {
      color: '#d53a3a',
    },
    hint: {
      marginBottom: '2em',
      color: '#d53a3a',
      margin: '8px',
    },
  })
)

interface Props {
  duplicateMod: boolean
}

export default (duplicateMod: Props) => {
  const [campaignTemplate, setCampaignTemplate] =
    useState<CampaignTemplate>(undefined)
  const [licenses, setLicenses] = useState<License[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [actualLanguage, setActualLanguage] = useState<Language>(undefined)
  const [themes, setThemes] = useState<Theme[]>([])
  const [domains, setDomains] = useState<Domain[]>([])
  const [contents, setContents] = useState<Content[]>([])
  const [goodPractices, setGoodPractices] = useState<GoodPractice[]>([])
  const [assignedDomains, setAssignedDomains] = useState<UUID[]>([])
  const [selectedDomains, setSelectedDomains] = useState<UUID[]>([])
  const [addADomain, setAddADomain] = useState(false)
  const [addATopic, setAddATopic] = useState(false)
  const [synchroThemesDomains, setSynchroThemesDomains] = useState(true)
  const [selectedTopics, setSelectedTopics] = useState<UUID[]>([])
  const [selectedGoodPractices, setSelectedGoodPractices] = useState<UUID[]>([])
  const [synchroGoodPracticesThemes, setSynchroGoodPracticesThemes] = useState(true)
  const prevAssignedDomains = usePrevious(assignedDomains)
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()
  const [domainsInfoIsChecked, setDomainsInfoIsChecked] = useState(false)
  const [topicsInfoIsChecked, setTopicsInfoIsChecked] = useState(false)
  const [bestpracticesInfoIsChecked, setBestpracticeInfoIsChecked] = useState(false)
  const [duplicatedContentInfoIsChecked, setDuplicatedContentInfoIsChecked] = useState(false)
  const [contentInfoIsChecked, setContentInfoIsChecked] = useState(false)
  const [fetchingCampaignTemplate, setFetchingCampaignTemplate] = useState(false)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!actualLanguage && languages.length > 0) {
      setActualLanguage(languages.find((l) => l.code == 'fr') || languages[0])
    }
  }, [languages])

  useEffect(() => {
    if (!loading && !fetchingCampaignTemplate)
      themeSynchroDomainRemove()
    if (synchroThemesDomains && selectedDomains.length != 0 && themes.length != 0
      && !fetchingCampaignTemplate)
      themeSynchroDomainAdd()
    if (fetchingCampaignTemplate)
      setFetchingCampaignTemplate(false)
    if (addADomain)
      AddThemesfromNewDomain()
  }, [selectedDomains])


  useEffect(() => {
    if (!loading && !fetchingCampaignTemplate)
      goodPracticeSynchroThemesRemove()
    if (synchroGoodPracticesThemes && selectedTopics.length != 0 && goodPractices.length != 0 &&
      !fetchingCampaignTemplate)
      goodPracticeSynchroThemesAdd()
    if (fetchingCampaignTemplate)
      setFetchingCampaignTemplate(false)
    if (addATopic)
      AddGoodPracticesfromNewDomain()
  }, [selectedTopics])

  useEffect(() => {
    if (assignedDomains && !loading && !fetchingCampaignTemplate) {
      if (assignedDomains.length > prevAssignedDomains.length)
        newAssignedDomain(assignedDomains[assignedDomains.length - 1])
      else
        removeAssignedDomain()
    }
  }, [assignedDomains])

  useEffect(() => {
    if (campaignTemplate && !fetchCampaignTemplate)
      setCampaignTemplate({
        ...campaignTemplate,
        boxOneOdds:
          100 -
          campaignTemplate.boxTwoOdds -
          campaignTemplate.boxThreeOdds -
          campaignTemplate.boxFourOdds,
      })
  }, [
    campaignTemplate?.boxOneOdds,
    campaignTemplate?.boxTwoOdds,
    campaignTemplate?.boxThreeOdds,
    campaignTemplate?.boxFourOdds,
  ])

  useEffect(() => {
    if (
      campaignTemplate && !loading && !fetchingCampaignTemplate) {
      let selectedContents: Content[] = []

      contents.forEach((c) => {
        c.goodPractices.forEach((gp) => {
          if (selectedGoodPractices.includes(gp.id)) {
            selectedContents.push(c)
          }
        })
      })
      setCampaignTemplate({
        ...campaignTemplate,
        contents: [...new Set(selectedContents)],
      })
    }
    if (fetchingCampaignTemplate)
      setFetchingCampaignTemplate(false)
  }, [selectedGoodPractices])

  useEffect(() => {
    setLoading(!(languages && licenses && domains && themes && contents && goodPractices && campaignTemplate));
  }, [languages, licenses, domains, themes, contents, goodPractices, campaignTemplate])

  useEffect(() => {
    if (campaignTemplate && fetchingCampaignTemplate) {
      synchroCampaignTemplate()
    }
  }, [campaignTemplate])

  const fetchData = async () => {
    fetchLanguages()
    fetchLicenses()
    await fetchDomains()
    await fetchThemes()
    await fetchContents()
    await fetchGoodPractices()
    await fetchCampaignTemplate()
  }

  const removeAssignedDomain = () => {
    let index = selectedDomains.findIndex((d) => !assignedDomains.includes(d))
    if (index !== -1) {
      setSelectedDomains([
        ...selectedDomains.slice(0, index),
        ...selectedDomains.slice(index + 1, selectedDomains.length)
      ]);
    }
  }

  const newAssignedDomain = (id: UUID) => {
    let temp = [...selectedDomains]
    temp.push(id)
    setAddADomain(true)
    setAddATopic(true)
    setSelectedDomains(temp)
  }

  const synchroCampaignTemplate = () => {
    setSelectedDomains(campaignTemplate.domains.map(d => d.id))
    setSelectedTopics(campaignTemplate.themes.map(t => t.id))
    let goodPracticesId = campaignTemplate.contents.map(c => {
      let temp = c.goodPractices.map(gp => (gp.id))
      return temp[0]
    })
    let uniquePracticesId = [...new Set(goodPracticesId)]
    setSelectedGoodPractices(uniquePracticesId)
  }

  const goodPracticeSynchroThemesRemove = () => {
    let res = goodPractices.map(gp => {
      if (selectedTopics.some(t => gp.themes.find(theme => theme.id === t))) {
        return (gp.id)
      }
      return undefined
    })
    res = res.map(i => {
      if (selectedGoodPractices.includes(i)) {
        return i
      }
      return undefined
    })
    setSelectedGoodPractices(res)
  }

  const goodPracticeSynchroThemesAdd = () => {
    setSelectedGoodPractices(goodPractices.map((gp, index) => {
      if (selectedTopics.some(d => gp.themes.find(theme => theme.id === d))) {
        return gp.id
      }
      return undefined
    }))
    setSynchroGoodPracticesThemes(false)
  }

  const themeSynchroDomainRemove = () => {
    let res = themes.map(t => {
      if (selectedDomains.some(d => t.domains.find(domain => domain.id === d))) {
        return (t.id)
      }
      return undefined
    })
    res = res.map(i => {
      if (selectedTopics.includes(i)) {
        return i
      }
      return undefined
    })
    setSelectedTopics(res)
  }

  const AddGoodPracticesfromNewDomain = () => {
    let themeAdded = themes.map(theme => {
      if (theme.domains.some(d => d.id === assignedDomains[assignedDomains.length - 1]))
        return theme.id
      return undefined
    })
    let res = goodPractices.map(gp => {
      if (gp.themes.some(g => themeAdded.includes(g.id)) || selectedGoodPractices.some(g => g === gp.id))
        return gp.id
      return undefined
    })
    setSelectedGoodPractices(res)
    setAddATopic(false)
  }

  const AddThemesfromNewDomain = () => {
    let temp = themes.map(theme => {
      if (theme.domains.some(d => d.id === assignedDomains[assignedDomains.length - 1]))
        return theme.id
      if (selectedTopics.some(t => t === theme.id))
        return theme.id
      return undefined
    })
    setSelectedTopics(temp)
    setAddADomain(false)
  }

  const themeSynchroDomainAdd = () => {
    setSelectedTopics(themes.map((theme, index) => {
      if (selectedDomains.some(d => theme.domains.find(domain => domain.id === d))) {
        return theme.id
      }
      return undefined
    }))
    setSynchroThemesDomains(false)
  }

  const deselectItem = (id_reference: UUID, setter: SetStateAction<any>, state: UUID[]) => {
    const index = state.findIndex((id: string) => id === id_reference)
    if (index !== -1) {
      setter([
        ...state.slice(0, index),
        ...state.slice(index + 1, state.length)
      ]);
    }
  }

  const fetchCampaignTemplate = async () => {
    if (!id) {
      try {
        const res = await httpClient.req(ROUTES.FETCH_LICENSES_DOMAINS({ id: store.store.JWT.license_id }))
        setAssignedDomains(res.map((domain_id: any) => domain_id))
        setSelectedDomains(res.map((domain_id: any) => domain_id))
        setCampaignTemplate({
          contents: [],
          boxOneOdds: 90,
          boxTwoOdds: 10,
          boxThreeOdds: 0,
          boxFourOdds: 0,
        } as CampaignTemplate)
      } catch (e) {
        store.notify(Message.Error, t('Unable to retrieve license\'s domains'))
        console.warn(e)
      }
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_CAMPAIGN_TEMPLATE({ id }))
        if (duplicateMod.duplicateMod) {
          setCampaignTemplate({
            boxOneOdds: 90,
            boxTwoOdds: 10,
            boxThreeOdds: 0,
            boxFourOdds: 0,
            contentMaxRetries: res.contentMaxRetries,
            contents: res.contents,
            imageUrl: res.imageUrl,
            licenseId: res.licenseId,
            sessionDuration: res.sessionDuration,

          } as CampaignTemplate)
        }
        setAssignedDomains(res.domains.map((d: Domain) => d.id))
        setFetchingCampaignTemplate(true)
        setSynchroThemesDomains(false)
        setSynchroGoodPracticesThemes(false)
        if (!duplicateMod.duplicateMod)
          setCampaignTemplate(res)
      } catch (e) {
        store.notify(
          Message.Error,
          t('Unable to retrieve the training model')
        )
        console.warn(e)
      }
    }
  }

  const fetchContents = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CONTENTS({}))
      setContents(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve content'))
      console.warn(e)
    }
  }

  const fetchDomains = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_DOMAINS({}))
      setDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const fetchGoodPractices = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_GOOD_PRACTICES({}))
      setGoodPractices(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve best practice')
      )
      console.warn(e)
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES())
      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve topics'))
      console.warn(e)
    }
  }

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES())

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const submitCampaignTemplate = async () => {
    let route = null
    if (duplicateMod.duplicateMod === false) {
      route = id
        ? ROUTES.UPDATE_CAMPAIGN_TEMPLATE
        : ROUTES.CREATE_CAMPAIGN_TEMPLATE
    } else {
      route = ROUTES.CREATE_CAMPAIGN_TEMPLATE
    }
    try {
      const res = await httpClient.req(
        route({
          campaignTemplate: {
            ...campaignTemplate,
            selectedDomains: assignedDomains,
          },
        })
      )

      store.notify(
        Message.Notification,
        t('Training model has been saved successfully')
      )
      setCampaignTemplate(res)
      history.push(`/campaign_templates/`)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to save the training model')
      )
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const removeContent = (c: Content) => {
    setCampaignTemplate({
      ...campaignTemplate,
      contents: campaignTemplate.contents.filter((c2) => c2.id != c.id),
    })
  }

  const updateImage = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      setCampaignTemplate({ ...campaignTemplate, imageUrl: res.imageUrl })
    } catch (e) {
      store.notify(Message.Error, t("Unable to update the image"))
      console.warn(e)
    }
  }

  const canBeSubmitted = () => {
    const hasNotEmptyName =
      !!campaignTemplate.campaignTemplateTranslations?.find(
        (ctt) => ctt.title && ctt.title.length > 0
      )
    const hasContents = campaignTemplate.contents.length > 0
    const hasImage = campaignTemplate.imageUrl

    return hasNotEmptyName && hasContents && hasImage && boxesOddsAreOk()
  }

  const boxesOddsAreOk = () =>
    campaignTemplate.boxOneOdds >= 0 &&
    campaignTemplate.boxTwoOdds >= 0 &&
    campaignTemplate.boxThreeOdds >= 0 &&
    campaignTemplate.boxFourOdds >= 0

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {duplicateMod.duplicateMod == true ? (
          <div>
            {t('Duplicate')} {t('a training model')}
          </div>
        ) : (
          id ? (
            <div>{t('Edit')} {t('a training model')}</div>
          ) : (
            <div>
              {t('Create')} {t('a training model')}
            </div>
          )
        )}
      </Typography>
      {!loading && languages.length > 0 ? (
        <form onSubmit={submitCampaignTemplate}>
          <div className={classes.formControl}>
            <FormControl className={classes.formControl}>
              <TranslatableTextField
                className={classes.inputField}
                value={campaignTemplate.campaignTemplateTranslations || []}
                translationKey="title"
                label={t('Title')}
                onChange={(v) =>
                  setCampaignTemplate({
                    ...campaignTemplate,
                    campaignTemplateTranslations: v,
                  })
                }
                languages={languages}
                language={actualLanguage}
                onLanguageChange={(id) =>
                  setActualLanguage(languages.find((l) => l.id == id))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TranslatableTextField
                className={classes.inputField}
                value={campaignTemplate.campaignTemplateTranslations || []}
                translationKey="subtitle"
                label={t('Subtitle')}
                onChange={(v) =>
                  setCampaignTemplate({
                    ...campaignTemplate,
                    campaignTemplateTranslations: v,
                  })
                }
                languages={languages}
                language={actualLanguage}
                onLanguageChange={(id) =>
                  setActualLanguage(languages.find((l) => l.id == id))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TranslatableTextField
                className={classes.inputField}
                value={campaignTemplate.campaignTemplateTranslations || []}
                translationKey="description"
                label={t('Description')}
                onChange={(v) =>
                  setCampaignTemplate({
                    ...campaignTemplate,
                    campaignTemplateTranslations: v,
                  })
                }
                languages={languages}
                language={actualLanguage}
                onLanguageChange={(id) =>
                  setActualLanguage(languages.find((l) => l.id == id))
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography component="h2" variant="h6" className={classes.title}>
                {t('Image')}
              </Typography>
              <div className={clsx(classes.templateImage, classes.inputField)}>
                {campaignTemplate.imageUrl && (
                  <img
                    src={campaignTemplate.imageUrl}
                    className={classes.templateImageTag}
                  />
                )}
                <input
                  type="file"
                  className={classes.templateImageFile}
                  onChange={(e) => updateImage(e.target.files[0])}
                  accept="image/jpeg, image/png, image/jpg"
                />
              </div>
            </FormControl>
            {store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('License')}</InputLabel>
                <Select
                  value={campaignTemplate.licenseId || ''}
                  onChange={(v) => {
                    setCampaignTemplate({
                      ...campaignTemplate,
                      licenseId: v.target.value as UUID,
                    })
                  }}
                  className={classes.inputField}
                >
                  <MenuItem key="all" value={''}>
                    {t('No license attached')}
                  </MenuItem>
                  {licenses.map((l) => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.inputField}
                label={t("Duration of a session (in seconds)")}
                value={campaignTemplate.sessionDuration}
                onChange={(e) =>
                  setCampaignTemplate({
                    ...campaignTemplate,
                    sessionDuration: +e.target.value,
                  })
                }
                type="number"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                className={classes.inputField}
                label={t("Maximum number of repetitions for a content to be displayed")}
                value={campaignTemplate.contentMaxRetries}
                onChange={(e) =>
                  setCampaignTemplate({
                    ...campaignTemplate,
                    contentMaxRetries: +e.target.value,
                  })
                }
                type="number"
              />
            </FormControl>
            {store.store.JWT && store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('Domains')}</InputLabel>
                <Select
                  value={assignedDomains}
                  onChange={(v) => {

                    setAssignedDomains([...(v.target.value as UUID[])])
                  }}
                  className={classes.inputField}
                  multiple
                >
                  {domains
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {assignedDomains.length == 0 && campaignTemplate.licenseId ? (
              <div className={classes.hint}>
                {t(
                  'Assign at least one area to select the contents of the training model.'
                )}
              </div>
            ) : (
              <div className='questions'>
                <div className='section'>
                  <div className='header'>
                    <label>Domains</label>
                    <div className='--button' style={{ marginLeft: "1em" }} onChange={() => {
                      setDomainsInfoIsChecked(!(domainsInfoIsChecked))
                    }}><PlusToMinus name='domains'></PlusToMinus></div>
                    <div className='resetButton'>
                      <div onClick={() => {
                        setSynchroThemesDomains(true)
                        setSynchroGoodPracticesThemes(true)
                        setSelectedDomains([...(assignedDomains as UUID[])])
                      }}>Reset</div>
                    </div>
                  </div>
                  {domainsInfoIsChecked ? (
                    <ul>
                      {domains.filter((d) =>
                        store.store.JWT.role == 'admin' || store.store.JWT.role == 'superadmin'
                          ? selectedDomains.includes(d.id)
                          : true
                      ).map((c) => (
                        <li className={classes.contentEntry} key={c.id}>
                          {c.name}
                          <i
                            className={classes.removeContent}
                            onClick={() => {
                              deselectItem(c.id, setSelectedDomains, selectedDomains)
                            }}
                          >
                            close
                          </i>
                        </li>
                      ))}
                    </ul>
                  ) : (null)}
                </div>
                <div className='section'>
                  <div className='header'>
                    <label className='questions__titleContent'>Topics</label>
                    <div className='--button' style={{ marginLeft: "1em" }} onChange={() => {
                      setTopicsInfoIsChecked(!(topicsInfoIsChecked))
                    }}><PlusToMinus name='topics'></PlusToMinus>
                    </div>
                  </div>
                  {topicsInfoIsChecked ? (
                    <ul>
                      {themes.filter((th) =>
                        store.store.JWT.role == 'admin' || store.store.JWT.role == 'superadmin'
                          ? selectedTopics.includes(th.id)
                          : true
                      ).map((t) => (
                        <li className={classes.contentEntry} key={t.id}>
                          {t.name}
                          <i
                            className={classes.removeContent}
                            onClick={() => {
                              deselectItem(t.id, setSelectedTopics, selectedTopics)
                            }}
                          >
                            close
                          </i>
                        </li>
                      ))}
                    </ul>
                  ) : (null)}
                </div>
                <div className='section'>
                  <div className='header'>
                    <label className='questions__titleContent'>Best Practice</label>
                    <div className='--button' style={{ marginLeft: "1em" }} onChange={() => {
                      setBestpracticeInfoIsChecked(!(bestpracticesInfoIsChecked))
                    }}><PlusToMinus name='bestPratice'></PlusToMinus></div>
                  </div>
                  {bestpracticesInfoIsChecked ? (
                    <ul>
                      {goodPractices.filter((gp) =>
                        store.store.JWT.role == 'admin' || store.store.JWT.role == 'superadmin'
                          ? selectedGoodPractices.includes(gp.id)
                          : true
                      ).map((g) => (
                        <li className={classes.contentEntry} key={g.id}>
                          {g.name}
                          <i
                            className={classes.removeContent}
                            onClick={() => {
                              deselectItem(g.id, setSelectedGoodPractices, selectedGoodPractices)
                            }}>
                            close
                          </i>
                        </li>
                      ))}
                    </ul>
                  ) : (null)}
                </div>
                <div className='section'>
                  <div className='header'>
                    <label className='questions__titleContent'>Duplicated contents</label>
                    <div className='--button' style={{ marginLeft: "1em" }} onChange={() => {
                      setDuplicatedContentInfoIsChecked(!(duplicatedContentInfoIsChecked))
                    }}><PlusToMinus name='duplicatedContents'></PlusToMinus></div>
                  </div>
                  {duplicatedContentInfoIsChecked ? (
                    <ul>
                      {(campaignTemplate.contents || []).map((c, index) => {
                        if (c.isDuplicated === true) {
                          return (
                            <li className={classes.contentEntry} key={c.id}>
                              {c.title} - {`${c.points} ${t('points')}`}
                              <i
                                className={classes.removeContent}
                                onClick={() => {
                                  removeContent(c)
                                }
                                }
                              >
                                close
                              </i>
                              {c.idSourceDuplicate ? (
                                <div>[ {
                                  contents.find((content) => content.id === c.idSourceDuplicate)?.title
                                  } ]</div>
                              ) : (null)}
                            </li>
                          )
                        } else {
                          return (null)
                        }
                      })}
                    </ul>
                  ) : (null)}
                </div>
                <div className='section'>
                  <div className='header'>
                    <label className='questions__titleContent'>Contents</label>
                    <div className='--button' style={{ marginLeft: "1em" }} onChange={() => {
                      setContentInfoIsChecked(!(contentInfoIsChecked))
                    }}><PlusToMinus name='contents'></PlusToMinus></div>
                  </div>
                  {contentInfoIsChecked ? (
                    <ul>
                      {(campaignTemplate.contents || []).map((c) => (
                        <li className={classes.contentEntry} key={c.id}>
                          {c.title} - {`${c.points} ${t('points')}`}
                          <i
                            className={classes.removeContent}
                            onClick={() => {
                              removeContent(c)
                            }
                            }
                          >
                            close
                          </i>
                        </li>
                      ))}
                    </ul>
                  ) : (null)}
                </div>
              </div>
            )}
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<i>expand_more</i>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography component="h2" variant="h5">
                  {t('Repetition interval')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className={clsx(classes.contents, classes.accordionContent)}
              >
                <p>
                  {t(
                    "Here you can set the spaced repetition. If you want to use less boxes, you can set the percentage of appearance of the last (unused) boxes to 0."
                  )}
                  {!boxesOddsAreOk() && (
                    <div className={classes.errorHint}>
                      {t(
                        "Content appearance percentages cannot be negative."
                      )}
                    </div>
                  )}
                </p>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.inputField}
                    label={t("Percentage of occurrence") + ' ' + t("box") + '1'}
                    value={campaignTemplate.boxOneOdds || 100}
                    onChange={(e) =>
                      setCampaignTemplate({
                        ...campaignTemplate,
                        boxOneOdds: +e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.inputField}
                    label={t("Percentage of occurrence") + ' ' + t("box") + '2'}
                    value={campaignTemplate.boxTwoOdds || 0}
                    onChange={(e) =>
                      setCampaignTemplate({
                        ...campaignTemplate,
                        boxTwoOdds: +e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.inputField}
                    label={t("Percentage of occurrence") + ' ' + t("box") + '3'}
                    value={campaignTemplate.boxThreeOdds || 0}
                    onChange={(e) =>
                      setCampaignTemplate({
                        ...campaignTemplate,
                        boxThreeOdds: +e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.inputField}
                    label={t("Percentage of occurrence") + ' ' + t("box") + '4'}
                    value={campaignTemplate.boxFourOdds || 0}
                    onChange={(e) =>
                      setCampaignTemplate({
                        ...campaignTemplate,
                        boxFourOdds: +e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Button
              variant="contained"
              color="primary"
              onClick={submitCampaignTemplate}
              disabled={!canBeSubmitted()}
            >
              {t('Save')}
            </Button>
          </div>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )
      }
    </div >
  )
}
