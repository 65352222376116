import React, { useState, useEffect } from 'react'
import User from '../../../types/user'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'

const useStyles = makeStyles((user: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: user.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    errors: {
      color: '#f74e52',
      marginBottom: '1em',
    },
  })
)

export default () => {
  const [user, setUser] = useState<User>(undefined)
  const [licenses, setLicenses] = useState<License[]>([])
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()

  const computeErrors = () => {
    if (!user) return []
    else {
      const res = []
      const hasNotEmptyName =
        user.firstName &&
        user.firstName.length > 0 &&
        user.lastName &&
        user.lastName.length > 0
      const hasNotEmptyEmail = user.email && user.email.length > 0
      // const hasLicense = user.licenseId && user.licenseId.length > 0
      const hasValidPassword = user.password && user.password.length >= 8
      const passwordsMatch = user.password == user.passwordConfirmation

      if (!hasNotEmptyName) {
        res.push(t('The first and last name must be filled in.'))
      }
      if (!hasNotEmptyEmail) {
        res.push(t("The email must be filled in."))
      }
      if (!hasValidPassword) {
        res.push(t('The password must be at least 8 characters long.'))
      }
      if (!passwordsMatch) {
        res.push(t('The passwords are not identical.'))
      }
      return res
    }
  }

  const errors = computeErrors()

  useEffect(() => {
    fetchUser()
    fetchLicenses()
  }, [])

  const fetchUser = async () => {
    if (!id) {
      setUser({} as User)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_USER({ id }))

        setUser(res)
      } catch (e) {
        store.notify(Message.Error, t("Unable to retrieve the user"))
        console.warn(e)
      }
    }
  }

  const submitUser = async () => {
    let route = id ? ROUTES.UPDATE_USER : ROUTES.CREATE_USER

    try {
      const res = await httpClient.req(
        route({
          user,
        })
      )

      store.notify(
        Message.Notification,
        t('User successfully saved')
      )
      setUser(res)
      history.push(`/users/`)
    } catch (e) {
      store.notify(Message.Error, t("Unable to save the user"))
      console.warn(e)
    }
  }

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {id ? t('Edit') : t('Create')} {t('user')}
      </Typography>
      {user ? (
        <form onSubmit={submitUser}>
          <FormControl className={classes.formControl}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('First name')}
                className={classes.inputField}
                value={user.firstName}
                onChange={(e) =>
                  setUser({ ...user, firstName: e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Name')}
                className={classes.inputField}
                value={user.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Email')}
                className={classes.inputField}
                value={user.email}
                type="email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Password')}
                className={classes.inputField}
                value={user.password}
                type="password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Password confirmation')}
                className={classes.inputField}
                value={user.passwordConfirmation}
                type="password"
                onChange={(e) =>
                  setUser({ ...user, passwordConfirmation: e.target.value })
                }
              />
            </FormControl>
            {store.store.JWT.role == 'superadmin' && (
              <FormControl className={classes.formControl}>
                <InputLabel>{t('License')}</InputLabel>
                <Select
                  value={user.licenseId || ''}
                  onChange={(v) => {
                    setUser({ ...user, licenseId: v.target.value as UUID })
                  }}
                  className={classes.inputField}
                >
                  {licenses.map((l) => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div className={classes.errors}>
              {errors.map((e) => (
                <div>{e}</div>
              ))}
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={submitUser}
              disabled={errors.length != 0}
            >
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
