import React, { useState, useEffect } from 'react'
import Language from '../../../types/language'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  Button,
} from '@material-ui/core'
import './LanguageForm.scss'
import clsx from 'clsx'

const useStyles = makeStyles((language: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: language.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    languageImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    languageImageTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    languageImageFile: {
      marginBottom: '1em',
    },
  })
)

export default () => {
  const [language, setLanguage] = useState<Language>(undefined)
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchLanguage()
  }, [])

  const fetchLanguage = async () => {
    if (!id) {
      setLanguage({} as Language)
      return
    }

    try {
      const res = await httpClient.req(
        ROUTES.FETCH_LANGUAGE({
          id,
        })
      )

      setLanguage(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve language'))
      console.warn(e)
    }
  }

  const submitLanguage = async () => {
    let route = id ? ROUTES.UPDATE_LANGUAGE : ROUTES.CREATE_LANGUAGE
    try {
      const res = await httpClient.req(
        route({
          language,
        })
      )

      store.notify(Message.Notification, t('Language saved successfully'))
      history.push('/languages')
      setLanguage(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to save language'))
      console.warn(e)
    }
  }

  const updateImage = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      setLanguage({ ...language, imageUrl: res.imageUrl })
    } catch (e) {
      store.notify(Message.Error, t("Unable to update the image"))
      console.warn(e)
    }
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {id ? t('Edit') : t('Create')} {t('language')}
      </Typography>
      {language && (
        <form onSubmit={submitLanguage}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('Name')}
              className={classes.inputField}
              value={language.name}
              onChange={(e) =>
                setLanguage({ ...language, name: e.target.value })
              }
            />
            <TextField
              required
              variant="outlined"
              fullWidth
              label={t('ISO Code')}
              className={classes.inputField}
              value={language.code}
              onChange={(e) =>
                setLanguage({ ...language, code: e.target.value })
              }
            />
            <Typography component="h2" variant="h6" className={classes.title}>
              {t('Image')}
            </Typography>
            <div className={clsx(classes.languageImage, classes.inputField)}>
              {language.imageUrl && (
                <img
                  src={language.imageUrl}
                  className={classes.languageImageTag}
                />
              )}
              <input
                type="file"
                className={classes.languageImageFile}
                onChange={(e) => updateImage(e.target.files[0])}
                accept="image/jpg, image/jpeg, image/png"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={submitLanguage}
            >
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      )}
    </div>
  )
}
