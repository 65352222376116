import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { Method, Route, BASE_URL } from '../routes'
import { JWT } from '../types/auth'
import i18n, { t, stringToLng } from './i18n'
import store, { Message, Stored } from './store'
import humps from 'humps'

interface Request {
  resolve: any
  reject: any
  route: Route
}

type ErrorHandler = (err: string | undefined) => void
type ResponseHandler = (resp: AxiosResponse) => void

class HttpClient {
  public pendingRequests: Request[]
  public isRefreshing?: boolean
  public jwt?: JWT
  public rawJwt?: string
  public token?: string

  constructor() {
    const savedState = JSON.parse(localStorage.getItem('state')) || {}

    this.pendingRequests = []
    this.jwt = savedState[Stored.JWT]
    this.rawJwt = savedState[Stored.RawJWT]
    // this.token = savedState[Stored.RefreshToken]
  }

  objectToFormData(obj: any, form?: any, namespace?: any) {
    var fd = form || new FormData()
    var formKey

    for (var property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + '[' + property + ']'
        } else {
          formKey = property
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          this.objectToFormData(obj[property], fd, property)
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property])
        }
      }
    }

    return fd
  }

  public async req(
    route: Route,
    errorHandler?: ErrorHandler,
    responseHandler?: ResponseHandler
  ) {
    let { params, method, data, auth, file } = route
    const headers: any = {}

    if (auth) {
      if (this.isRefreshing) {
        return this.setPendingRequest(route)
      }
      if (!this.jwt) {
        store.notify(Message.NeedAuth)
        return Promise.reject('Please log in to continue') // TODO I18n
      }
      // if (this.jwt.exp <= new Date().getTime() / 1000 + 60) {
      //   const pendingRequest = this.setPendingRequest(route)

      //   this.isRefreshing = true
      //   this.refreshJwt()
      //   return pendingRequest
      // }
      headers['Authorization'] = `Bearer ${this.rawJwt}`
    }
    if (file) {
      data = new FormData()
      data.append('file', file)
    }
    try {
      return [Method.Get, Method.Delete].includes(method)
        ? (axios[method] as any)(this.genUrl(route), {
            params: humps.decamelizeKeys(params),
            headers,
          })
            .then((e: any) => {
              if (responseHandler) {
                responseHandler(e)
              }
              return humps.camelizeKeys(e.data)
            })
            .catch((err: { response?: AxiosResponse }) =>
              this.handleError(err, errorHandler)
            )
        : (axios[method] as any)(
            this.genUrl(route),
            file ? data : humps.decamelizeKeys(data),
            {
              params: humps.decamelizeKeys(params),
              headers,
            }
          )
            .then((e: any) => {
              if (responseHandler) {
                responseHandler(e)
              }
              return humps.camelizeKeys(e.data)
            })
            .catch((err: { response?: AxiosResponse }) =>
              this.handleError(err, errorHandler)
            )
    } catch (err) {
      this.handleError(err, errorHandler)
    }
  }

  public storeCreds(token: string): [string, JWT] {
    const state = JSON.parse(localStorage.getItem('state')) || {}

    this.jwt = jwtDecode<JWT>(token)
    this.rawJwt = token
    state[Stored.JWT] = this.jwt
    state[Stored.RawJWT] = this.rawJwt
    i18n.setLng(stringToLng(this.jwt.language))
    store.update(Stored.Language, stringToLng(this.jwt.language))
    localStorage.setItem('state', JSON.stringify(state))
    return [this.rawJwt, this.jwt]
  }

  private handleError(
    error: { response?: AxiosResponse },
    handler?: ErrorHandler
  ) {
    const err =
      (error.response && error.response.data && error.response.data.error) ||
      undefined

    if (error.response && error.response.status == 401) {
      store.notify(Message.NeedAuth)
      throw error
    }

    if (handler) {
      handler(err)
    } else if (error.response) {
      err && store.notify(Message.Error, t(err))
    }
    throw error
  }

  // private refreshJwt() {
  //   if (!this.token) {
  //     return store.notify(Message.NeedAuth)
  //   }

  //   this.req(ROUTES.REFRESH_JWT(this.token))
  //     .then((res: AuthResponse) => {
  //       this.isRefreshing = false
  //       this.storeCreds(res)
  //       this.pendingRequests.forEach((r) => {
  //         this.req(r.route).then(r.resolve).catch(r.reject)
  //       })
  //     })
  //     .catch(() => {
  //       this.pendingRequests = []
  //       store.notify(Message.NeedAuth)
  //     })
  // }

  private genUrl(route: Route): string {
    const urlReg = /^(?:https?:\/\/)?([0-9a-z-.:]+)\/?/i
    // const baseUrl = BASE_URL.replace(urlReg, 'https://$1/')
    return route.extern ? route.path : BASE_URL + route.path
  }

  private setPendingRequest(route: Route): Promise<any> {
    const promise = new Promise((resolve: any, reject: any) => {
      this.pendingRequests.push({ resolve, reject, route })
    })

    return promise
  }
}

const httpClient = new HttpClient()

export default httpClient
