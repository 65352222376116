import React, { useEffect, useState } from 'react'
import httpClient from '../../services/httpClient'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import ROUTES from '../../routes'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Typography, Button, makeStyles, TextField } from '@material-ui/core'
import CampaignTemplate from '../../types/campaign_template'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import License from '../../types/license'
import Loader from 'react-loader-spinner';

const useStyles = makeStyles({
  root: {
    padding: '3em 5em',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  title: {},
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',
  },
  button: {
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    textDecoration: 'none',
    marginRight: '2em',
  },
  sortable: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center;',
    '& i': {
      marginLeft: '0.75em',
    },
  },
  filters: {
    display: 'flex',
    marginBottom: '2em',
  },
  filter: {
    minWidth: '15em',
    marginRight: '2em',
    '&:last-child': {
      marginRight: 0,
    },
  },
})

export default () => {
  const [campaignTemplates, setCampaignTemplates] = useState<
    CampaignTemplate[]
  >([])
  const classes = useStyles()
  const [filters, setFilters] = useState({
    title: '',
  })
  const [orderBy, setOrderBy] = useState<{ field: string; type: string }>({
    field: undefined,
    type: undefined,
  })
  const [licenses, setLicenses] = useState<License[]>([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!(campaignTemplates && licenses));
  }, [campaignTemplates, licenses])

  useEffect(() => {
    fetchCampaignTemplates()
    fetchLicenses()
  }, [])

  const fetchLicenses = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_LICENSES())

      setLicenses(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve licenses'))
      console.warn(e)
    }
  }

  const fetchCampaignTemplates = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_CAMPAIGN_TEMPLATES({}))

      setCampaignTemplates(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Unable to retrieve training models')
      )
      console.warn(e)
    }
  }

  const deleteCampaignTemplate = async (
    campaign_template: CampaignTemplate
  ) => {
    if (
      window.confirm(
        t(
          'Are you sure you want to delete this user? Any dependent content will also be deleted.'
        )
      )
    ) {
      try {
        await httpClient.req(
          ROUTES.DELETE_CAMPAIGN_TEMPLATE({ id: campaign_template.id })
        )

        setCampaignTemplates(
          campaignTemplates.filter((c) => c.id != campaign_template.id)
        )
      } catch (e) {
        store.notify(Message.Error, t("Unable to delete the user"))
        console.warn(e)
      }
    }
  }

  const filteredCampaignTemplates = () => {
    let res = [...campaignTemplates]

    if (filters.title && filters.title != '') {
      res = res.filter((t) =>
        t.title.toLowerCase().includes(filters.title.toLowerCase())
      )
    }
    return res
  }

  return (
    <div className={classes.root}>
      {loading ? (
        <Loader type="ThreeDots" color="#f9ac30" height={100} width={100} />
      ) : (
        <><div className={classes.titleWrapper}>
          <Typography component="h1" variant="h3" className={classes.title}>
            {t('Training models')}
          </Typography>
          <a className={classes.linkButton} href="/campaign_templates/new">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            >
              + {t('Create')}
            </Button>
          </a>
        </div><div className={classes.filters}>
            <TextField
              className={classes.filter}
              label={t('Filter by name')}
              value={filters.title}
              onChange={(e) => setFilters({ ...filters, title: e.target.value })} />
          </div><TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={clsx(classes.tableHeadCell)}
                    onClick={() => setOrderBy({
                      field: 'title',
                      type: orderBy.type == 'asc' ? 'desc' : 'asc',
                    })}
                  >
                    <div className={clsx(classes.sortable)}>
                      {t('Name')}
                      {orderBy.field == 'title' && (
                        <i>
                          {orderBy.type == 'desc' ? 'expand_more' : 'expand_less'}
                        </i>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    {t('Subtitle')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    {t('Description')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    {t('License')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(orderBy.field
                  ? filteredCampaignTemplates().sort((t1: any, t2: any) => {
                    let a = t1[orderBy.field]
                    let b = t2[orderBy.field]

                    try {
                      a = a.toLowerCase()
                      b = b.toLowerCase()
                    } catch { }

                    let res = a >= b ? 1 : -1

                    if (orderBy.type == 'desc')
                      res *= -1
                    return res
                  })
                  : filteredCampaignTemplates()
                ).map((ct) => (
                  <TableRow key={ct.id}>
                    <TableCell component="th" scope="row">
                      {ct.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {ct.subtitle}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {ct.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {licenses.find((license) => license.id == ct.licenseId) ? (
                        licenses.find((license) => license.id == ct.licenseId).name
                      ) : (null)}
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {store.store.JWT.role == 'superadmin' ||
                        ct.licenseId == store.store.JWT.license_id ? (
                        <div className={classes.centered}>
                          <a
                            className={classes.linkButton}
                            href={`/campaign_templates/${ct.id}`}
                          >
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                            >
                              {t('Edit')}
                            </Button>
                          </a>
                          <a className={classes.linkButton}
                            href={`/campaign_templates/duplicate/${ct.id}`}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                            >
                              {t('Duplicate')}
                            </Button>
                          </a>
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteCampaignTemplate(ct)}
                          >
                            {t('Delete')}
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                          <a className={classes.linkButton}
                            href={`/campaign_templates/duplicate/${ct.id}`}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                            >
                              {t('Duplicate')}
                            </Button>
                          </a>
                          <div>
                            <div className={classes.centered} style={{ fontSize: "bold", textTransform: "uppercase" }}>
                              {t('Can\'t be modified')}
                            </div>
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer></>
      )}
    </div>
  )
}
