export default {
  Connexion: {
    en: 'Login',
    fr: 'Connexion',
  },
  Email: {
    en: 'Email',
    fr: 'Email',
  },
  'Mot de passe': {
    en: 'Password',
    fr: 'Mot de passe',
  },
  'Vous avez oublié votre mot de passe ?': {
    en: 'Did you forget your password?',
    fr: 'Vous avez oublié votre mot de passe ?',
  },
  'Vous êtes connecté avec succès': {
    en: 'You are successfully connected',
    fr: 'Vous êtes connecté avec succès',
  },
  'Email ou mot de passe incorrect': {
    en: 'Invalid email or password',
    fr: 'Email ou mot de passe incorrect',
  },
}
