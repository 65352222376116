import React, { useState, useEffect, useRef } from 'react'
import License from '../../../types/license'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Loader from 'react-loader-spinner'
import clsx from 'clsx'
import Calendar from 'react-calendar'
import Domain from '../../../types/domain'
import { UUID } from '../../../types/common'
import './LicenseForm.scss'
import { HexColorPicker as Picker } from 'react-colorful';

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    checkbox: {
      marginLeft: '-0.25em',
      marginBottom: '2em',
    },
    licenseLogo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    licenseLogoTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    licenseLogoFile: {
      marginBottom: '1em',
    },
  })
)

export default () => {
  const [license, setLicense] = useState<License>(undefined)
  const { id } = useParams<any>()
  const classes = useStyles()
  const history = useHistory()
  const [domains, setDomains] = useState<Domain[]>([])
  const [selectedDomains, setSelectedDomains] = useState<UUID[]>([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await fetchDomains()
    await fetchLicense()
  }

  const fetchDomains = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_DOMAINS())

      setDomains(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve domains'))
      console.warn(e)
    }
  }

  const fetchLicense = async () => {
    if (!id) {
      setLicense({} as License)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_LICENSE({ id }))
        if (!(res.primaryColor)) {
          setLicense({ ...license, primaryColor: '#000000' })
        }
        if (!(res.secondaryColor)) {
          setLicense({ ...license, secondaryColor: '#000000' })
        }
        setLicense(res)
        setSelectedDomains(res.domains?.map((d: any) => d.id) || [])
      } catch (e) {
        store.notify(Message.Error, t('Unable to retrieve the license'))
        console.warn(e)
      }
    }
  }

  const submitLicense = async () => {
    let route = id ? ROUTES.UPDATE_LICENSE : ROUTES.CREATE_LICENSE

    try {
      const res = await httpClient.req(
        route({
          license,
          selectedDomains,
        })
      )

      store.notify(
        Message.Notification,
        t('User saved successfully')
      )
      setLicense(res)
      history.push(`/licenses/`)
    } catch (e) {
      store.notify(Message.Error, t('Unable to save the license'))
      console.warn(e)
    }
  }

  const updateLogo = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }))

      setLicense({ ...license, logoUrl: res.imageUrl })

    } catch (e) {
      store.notify(Message.Error, t('Unable to update the logo'))
      console.warn(e)
    }
  }


  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {id ? t('Edit') : t('Create')} {t('license')}
      </Typography>
      {license ? (
        <form onSubmit={submitLicense}>
          <FormControl className={classes.formControl}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Name')}
                className={classes.inputField}
                value={license.name}
                onChange={(e) =>
                  setLicense({ ...license, name: e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className='title'>
                <div className='title__logo'>{t('Logo')}</div>
                <div className='title__color'>{t('Color')}</div>
              </div>
              <div className={clsx(classes.licenseLogo, classes.inputField)}>
                <div className='licenseCustom'>
                  <div>
                    {license.logoUrl && (
                      <img
                        src={license.logoUrl}
                        className={classes.licenseLogoTag}
                      />
                    )}
                  </div>
                  <div className='colorPicker'>
                    <div>
                      <strong >Primary</strong>
                      <Picker color={license.primaryColor} onChange={(color) => {
                        setLicense({ ...license, primaryColor: color })
                      }} style={{ height: '8em', width: "8em" }} />
                    </div>
                    <div>
                      <strong style={{ marginLeft: "1em" }}>Secondary</strong>
                      <Picker color={license.secondaryColor} onChange={(color) => {
                        setLicense({ ...license, secondaryColor: color })
                      }} style={{ height: '8em', width: "8em", marginLeft: '1em' }} />
                    </div>
                  </div>
                </div>
                <div className='underColrPicker'>
                  <input
                    type="file"
                    className={classes.licenseLogoFile}
                    onChange={(e) => updateLogo(e.target.files[0])}
                    accept="image/jpg, image/jpeg, image/png"
                  />
                  <div className='underColorPickerInput'>
                    <input className='underColorPickerInput__hexa'
                      onChange={(color) => {
                        setLicense({ ...license, primaryColor: color.target.value })
                      }} />
                    <input className='underColorPickerInput__hexa' onChange={(color) => {
                      setLicense({ ...license, secondaryColor: color.target.value })
                    }} />
                  </div>
                </div>
              </div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography component="h2" variant="h6" className={classes.title}>
                {t(" End of subscription")}
              </Typography>
              <Calendar
                defaultActiveStartDate={
                  license.subscriptionEndDate
                    ? new Date(license.subscriptionEndDate)
                    : new Date()
                }
                onChange={(v) =>
                  setLicense({ ...license, subscriptionEndDate: v as Date })
                }
                value={new Date(license.subscriptionEndDate)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Address')}
                className={classes.inputField}
                value={license.address}
                onChange={(e) =>
                  setLicense({ ...license, address: e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('Zip code')}
                className={classes.inputField}
                value={license.zipcode}
                onChange={(e) =>
                  setLicense({ ...license, zipcode: e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t('City')}
                className={classes.inputField}
                value={license.city}
                onChange={(e) =>
                  setLicense({ ...license, city: e.target.value })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t("Maximum number of users")}
                className={classes.inputField}
                type="number"
                value={license.learnersMax}
                onChange={(e) =>
                  setLicense({
                    ...license,
                    learnersMax: +e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Certification period')}</InputLabel>
              <Select
                value={license.certificationPeriod || ''}
                onChange={(e) =>
                  setLicense({
                    ...license,
                    certificationPeriod: e.target.value as string,
                  })
                }
              >
                <MenuItem key={'day'} value={'day'}>
                  {t('Day')}
                </MenuItem>
                <MenuItem key={'week'} value={'week'}>
                  {t('Week')}
                </MenuItem>
                <MenuItem key={'month'} value={'month'}>
                  {t('Month')}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                required
                fullWidth
                label={t("Maximum number of tests per certification period")}
                className={classes.inputField}
                type="number"
                value={license.certificationTriesPerPeriod}
                onChange={(e) =>
                  setLicense({
                    ...license,
                    certificationTriesPerPeriod: +e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Domains')}</InputLabel>
              <Select
                value={selectedDomains}
                onChange={(v) => {
                  setSelectedDomains(v.target.value as UUID[])
                }}
                className={classes.inputField}
                multiple
              >
                {domains
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={license.isDemo}
                  onChange={(e) =>
                    setLicense({
                      ...license,
                      isDemo: e.target.checked,
                    })
                  }
                />
              }
              label="Demo license"
            />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={license.isGame}
                  onChange={(e) =>
                    setLicense({
                      ...license,
                      isGame: e.target.checked,
                    })
                  }
                />
              }
              label="Game license"
            />
            <Button variant="contained" color="primary" onClick={() => {
              submitLicense()
            }}>
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
