import { UUID } from './common'
import { Answer } from './answer'
import GoodPractice from './good_practice'

export interface Content {
  id: UUID
  contentType: string
  licenseId?: UUID
  duration: number
  title: string
  correction: string
  answers?: Answer[]
  points: number
  goodPractices?: GoodPractice[]
  contentTranslations: { locale: string; title: string; correction: string }[]
  imageUrl?: string
  correctionImageUrl?: string
  mediaXl: boolean
  isDuplicated: boolean
  idSourceDuplicate: UUID
}

export enum ContentType {
  OneAnswerQuestion = 'one_answer_question',
  MultipleAnswerQuestion = 'multiple_answer_question',
  SortableQuestion = 'sortable_question',
  Video = 'video',
}

export default Content
