import React from 'react'
import './Modal.scss'

interface Props {
  children: JSX.Element | JSX.Element[]
  className?: string
  dismiss?: () => void
}

export default (props: Props) => {
  return (
    <div
      className={`Modal${props.className ? ` ${props.className}` : ''}`}
      onClick={props.dismiss ? props.dismiss : undefined}
    >
      <div className="Modal__body" onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  )
}
