import React, { ChangeEvent, useState } from 'react'
import './ImportContentModal.scss'
import { t } from '../../../services/i18n'
import { Button } from '@material-ui/core'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'

interface Props {
  onImported?: () => void
}

export default (props: Props) => {
  const [file, setFile] = useState<File>(undefined)
  const [contentsCount, setContentsCount] = useState<number>(undefined)

  const updateRecap = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader()

    fileReader.onload = (e: any) => {
      setContentsCount(JSON.parse(e.target.result)?.length)
    }
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    setFile(e.target.files[0])
  }

  const importContents = async () => {
    try {
      const res = await httpClient.req(ROUTES.IMPORT_CONTENTS({ file }))

      store.notify(
        Message.Notification,
        t(
          'Content import completed. %successes imports, %duplicates duplicates and %errors errors',
          {
            successes: res.successes,
            duplicates: res.duplicates,
            errors: res.errors,
          }
        )
      )
      props.onImported && props.onImported()
    } catch (e) {
      store.notify(Message.Error, t("Unable to export content"))
      console.warn(e)
    }
  }

  return (
    <div className="ImportContentModal">
      <div className="ImportContentModal__title">
        {t('Import contents')}
      </div>
      <input type="file" onChange={updateRecap} />
      {contentsCount && (
        <div className="ImportContentModal__recap">
          {t('Number of detected contents : %count', { count: contentsCount })}
        </div>
      )}
      <Button variant="contained" color="primary" onClick={importContents}>
        {t('Import')}
      </Button>
    </div>
  )
}
