import React, { useState, useEffect } from 'react'
import Theme from '../../../types/theme'
import { RouteComponentProps, useParams, useHistory } from 'react-router-dom'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { t } from '../../../services/i18n'
import {
  Select,
  Chip,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  createStyles,
  Theme as UITheme,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core'
import './DomainForm.scss'
import { UUID } from '../../../types/common'
import License from '../../../types/license'
import Loader from 'react-loader-spinner'
import GoodPractice from '../../../types/good_practice'
import TranslatableTextField from '../../../components/TranslatableTextField'
import Language from '../../../types/language'
import clsx from 'clsx'
import Domain from '../../../types/domain'

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    root: {
      padding: '3em 5em',
    },
    title: {
      marginBottom: '1em',
    },
    inputField: {
      marginBottom: '1em',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '50em',
      maxWidth: '100em',
    },
    themeImage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    themeImageTag: {
      marginBottom: '1em',
      maxWidth: '20em',
      maxHeight: '20em',
    },
    themeImageFile: {
      marginBottom: '1em',
    },
  })
)

export default () => {
  const [domain, setDomain] = useState<Domain>(undefined)
  const [themes, setThemes] = useState<Theme[]>([])
  const [selectedThemes, setSelectedThemes] = useState<UUID[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [actualLanguage, setActualLanguage] = useState<Language>(undefined)
  const { id } = useParams()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    fetchDomain()
    fetchThemes()
    fetchLanguages()
  }, [])

  useEffect(() => {
    if (!actualLanguage && languages.length > 0) {
      setActualLanguage(languages.find((l) => l.code == 'fr') || languages[0])
    }
  }, [languages])

  const fetchDomain = async () => {
    if (!id) {
      setDomain({} as Domain)
    } else {
      try {
        const res = await httpClient.req(ROUTES.FETCH_DOMAIN({ id }))

        setDomain(res)
        setSelectedThemes(res.themes?.map((th: any) => th.id) || [])
      } catch (e) {
        store.notify(Message.Error, t('Unable to retrieve the domain'))
        console.warn(e)
      }
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES({}))

      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve themes'))
      console.warn(e)
    }
  }

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES())

      setLanguages(res)
    } catch (e) {
      store.notify(Message.Error, t('Unable to retrieve languages'))
      console.warn(e)
    }
  }

  const submitDomain = async () => {
    let route = id ? ROUTES.UPDATE_DOMAIN : ROUTES.CREATE_DOMAIN

    try {
      const res = await httpClient.req(
        route({
          domain: {
            ...domain,
            selectedThemes,
          },
        })
      )

      store.notify(Message.Notification, t('Domain successfully saved'))
      setDomain(res)
      history.push('/domains')
    } catch (e) {
      store.notify(Message.Error, t('Unable to save the domain'))
      console.warn(e)
    }
  }

  const canBeSubmitted = () => {
    const hasNotEmptyName = !!domain.domainTranslations?.find(
      (tt) => tt.name && tt.name.length > 0
    )

    return hasNotEmptyName
  }

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {id ? t('Edit') : t('Create')} {t('domain')}
      </Typography>
      {domain && languages.length > 0 ? (
        <form onSubmit={submitDomain}>
          <FormControl className={classes.formControl}>
            <TranslatableTextField
              className={classes.inputField}
              value={domain.domainTranslations || []}
              translationKey="name"
              label={t('Name')}
              onChange={(v) =>
                setDomain({
                  ...domain,
                  domainTranslations: v,
                })
              }
              languages={languages}
              language={actualLanguage}
              onLanguageChange={(id) =>
                setActualLanguage(languages.find((l) => l.id == id))
              }
            />
            <FormControl className={classes.formControl}>
              <InputLabel>{t('Topics')}</InputLabel>
              <Select
                value={selectedThemes}
                multiple
                onChange={(v) => {
                  setSelectedThemes(v.target.value as UUID[])
                }}
                className={classes.inputField}
                renderValue={(v: string[]) => (
                  <div>
                    {v.map((value) => (
                      <Chip
                        key={value}
                        label={themes.find((gp) => gp.id == value)?.name}
                      />
                    ))}
                  </div>
                )}
              >
                {themes.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={submitDomain}
              disabled={!canBeSubmitted()}
            >
              {t('Save')}
            </Button>
          </FormControl>
        </form>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
