export default {
  ou: {
    en: 'or',
    fr: 'ou',
  },
  et: {
    en: 'and',
    fr: 'et',
  },
  'Impossible de récupérer le profil': {
    en: 'Could not retrieve your profile',
    fr: 'Impossible de récupérer le profil',
  },
  'Impossible de récupérer les formations': {
    en: 'Could not retrieve the campaigns',
    fr: 'Impossible de récupérer les formations',
  },
  'Impossible de récupérer les langues': {
    en: 'Could not retrieve the languages',
    fr: 'Impossible de récupérer les langues',
  },
  'Impossible de récupérer les thèmes': {
    en: 'Could not retrieve the topics',
    fr: 'Impossible de récupérer les thèmes',
  },
  Précédent: {
    en: 'Go back',
    fr: 'Précédent',
  },
  Quitter: {
    en: 'Quit',
    fr: 'Quitter',
  },
  MAX_USERS_REACHED: {
    fr: "Nombre maximal d'utilisateurs atteint",
    en: 'Max number of users reached',
  },
  Edit: {
    fr: "Modifier",
    en: "Edit"
  },
  'a training model': {
    fr: "un modèle d'entraînement",
    en: "a training model"
  },
  Title: {
    fr: "Titre",
    en: "Title",
  },
  Save: {
    fr: 'Sauvegarder',
    en: 'Save'
  },
  Subtitle: {
    fr: "Sous-titre",
    en: "Subtitle"
  },
  Description: {
    fr: "Description",
    en: "Description"
  },
  Image: {
    fr: "Image",
    en: "Image",
  },
  License: {
    fr: "License",
    en: "License"
  },
  Licenses: {
    fr: "Licenses",
    en: "Licenses"
  },
  "No license attached": {
    fr: "Pas de license attachée",
    en: "No license attached"
  },
  "Duration of a session (in seconds)": {
    fr: "Durée de la session (en secondes)",
    en: "Duration of a session (in seconds)"
  },
  "Maximum number of repetitions for a content to be displayed": {
    fr: "Nombre maximal de répétitions d'un contenu pour être affiché",
    en: "Maximum number of repetitions for a content to be displayed"
  },
  Domains: {
    fr: "Domaines",
    en: "Domains"
  },
  Topics: {
    fr: "Thèmes",
    en: "Topics"
  },
  "Best practice": {
    fr: "Bonnes pratiques",
    en: "Best practices"
  },
  Contents: {
    fr: "Contenus",
    en: "Contents"
  },
  "Question with one valid answer": {
    fr: "Question avec une seule réponse valide",
    en: "Question with one valid answer"
  },
  "Question with several valid answers": {
    fr: "Question avec plusieurs réponses valides",
    en: "Question with several valid answers"
  },
  "Repetition interval": {
    fr: "Interval de répétition",
    eng: "Repetition interval"
  },
  Disconnect: {
    fr: "Déconnecter",
    en: "Disconnect"
  },
  "Training models": {
    fr: "Modèles d'entraînement",
    en: "Training models"
  },
  "Percentage of occurrence": {
    fr: "Pourcentage de l'occurence",
    en: "Percentage of occurence"
  },
  box: {
    fr: "boîte",
    en: "box"
  },
  Users: {
    fr: "Utilisateurs",
    en: "Users"
  },
  "List to be ordered": {
    fr: "Liste à être ordonnée",
    en: "List to be ordered"
  },
  Video: {
    fr: "Vidéo",
    en: "Video"
  },
  Language: {
    fr: "Langage",
    en: "Language"
  },
  Dashboard: {
    fr: "Tableau de bord",
    en: "Dashboard"
  },
  "Here you can set the spaced repetition. If you want to use less boxes, you can set the percentage of appearance of the last (unused) boxes to 0.": {
  },
  "points": {
  },
  Duplicate: {
    fr: "Dupliquer",
    en: "Duplicate"
  },
  Create: {
    fr: "Créer",
    en: "Create"
  },
  Delete: {
    fr: "Supprimer",
    en: "Delete"
  },
  Name: {
    fr: "Nom",
    en: "Name"
  },
  "Filter by name": {
    fr: "Filtre par nom",
    en: "Filter by name"
  }
}
